import Axios from "axios";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import ModalLoading from "../../ModalLoading";
import ListCard from "./ListCard";

// this is the view of the user's list index, it appears on the blade template user_list.index

const UserLists = ({ translationsString }) => {

	const translations = JSON.parse(translationsString);

	// states
	const [userLists, setUserLists] = useState(null);
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(null);

	/// AXIOS REQUESTS

	// get lists that the user has already created
	const getLists = async () => {
		const listApiUrl = window.location.href;

		try {
			const response = await Axios.get(listApiUrl);
			setIsLoading(false);
			if (response.data.success) {
				const responseObj = response.data.data;
				setUserLists(responseObj);
				setError(false);
			}
		} catch (err) {
			setIsLoading(false);
			setError(err.response.data.message);
		}
	};

	/// get users lists on load

	useEffect(() => {
		getLists();
		setIsLoading(true);
	}, []);


	return (
		<>
			{error && <div className="document-view__error">{error}</div>}

			{!error &&  (
				<>
					<section
						className="main-grid user-lists"
						aria-labelledby="list-title"
					>
						<div className="main-grid__margin"></div>
						<div className="main-grid__body">
							<h1 className="user-lists__title" id="list-title">{translations.list.my_lists}</h1>


							<div className="cards-grid--list">
								{isLoading &&(
									<ModalLoading/>
								)}
								{!isLoading && userLists && (
									userLists.map(list =>{
										return(
											<ListCard
												list={list}
												key={list.id}
												translations={translations}/>
										)
									}))}

							</div>
						</div>
						<div className="main-grid__margin"></div>
					</section>
				</>
			)}
		</>
	);
};

export default UserLists;

UserLists.propTypes = {
	currentLocale: PropTypes.string,
	translationsString: PropTypes.string,
};

if (document.getElementById("user-lists")) {
	const documentsElement = document.getElementById("user-lists");
	ReactDOM.render(
		<UserLists {...documentsElement.dataset} />,
		documentsElement
	);
}
