import React from 'react';
import PropTypes from "prop-types";


// The card that is seen on the MY LIST index page on mobile

const  ListCard = ({list}) =>{

	return (
		<div className="card-list">
			<a href={window.location.href +'/' + list.id}>
				<h3> {list.title}</h3>
				<p>{list.documents.length} documents</p>
			</a>
		</div>
	);
}

export default ListCard;

ListCard.propTypes = {
	list: PropTypes.object,
}

