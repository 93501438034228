import React from "react";
import PropTypes from "prop-types";
import qs from "qs";
import truncatePages from "../hooks/truncatePages";
import { useMediaQuery } from "react-responsive";

export default function Pagination({
	allPages,
	currentPage,
	arrowIcon,
	filters,
	currentLocale,
	pageName,
}) {
	const prevUrl = new URL(
		`${window.origin}/${currentLocale}/${pageName}?${qs.stringify(filters)}`
	);
	prevUrl.searchParams.set("page", currentPage - 1);
	const nextUrl = new URL(
		`${window.origin}/${currentLocale}/${pageName}?${qs.stringify(filters)}`
	);
	nextUrl.searchParams.set("page", currentPage + 1);

	const isDesktop = useMediaQuery({ query: "(min-width: 1220px)" });

	const lastPage = allPages.slice(-1).pop();
	// number of pages to show around active page
	const delta = isDesktop ? 2 : 1;

	const truncatedPages = truncatePages(currentPage, lastPage, delta)

	return (
		<div className="pagination">
			<div className="pagination__wrap">
				{/* hide prev button if on page 1 */}
				{allPages.length > 1 && currentPage > 1 && (
					<div className="pagination__arrow prev">
						<a href={prevUrl}>
							<img src={arrowIcon} alt="next page icon" />
						</a>
					</div>
				)}
				{truncatedPages.map((page) => {
					const pageUrl = new URL(
						`${
							window.origin
						}/${currentLocale}/${pageName}?${qs.stringify(filters)}`
					);
					pageUrl.searchParams.set("page", page);
					return (
						<a key={page} href={pageUrl}>
							<h3
								className={`pagination__page ${
									page === currentPage ? "current" : ""
								}`}
							>
								{page}
							</h3>
						</a>
					);
				})}
				{/* hide next button if on last page */}
				{allPages.length > 1 && currentPage < allPages.length && (
					<div className="pagination__arrow next">
						<a href={nextUrl}>
							<img src={arrowIcon} alt="next page icon" />
						</a>
					</div>
				)}
			</div>
		</div>
	);
}

Pagination.propTypes = {
	allPages: PropTypes.instanceOf(Object),
	currentPage: PropTypes.number,
	arrowIcon: PropTypes.string,
	filters: PropTypes.instanceOf(Object),
	currentLocale: PropTypes.string,
};
