import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import overflowHidden from "../../hooks/overflowHidden";
import Axios from "axios";

// The modal that displays user's List when they click "My Lists" in the header

const MyListModal = ({
	locale,
	myListModalOpen,
	setMyListModalOpen,
	createListModalOpen,
	setCreateListModalOpen,
	translations
}) => {
	const windowUrl = window.origin;

	//state
	const [userLists, setUserLists] = useState(null);
	const [error, setError] = useState(false);
	const [isLoading, setIsLoading] = useState(null);

	// ref
	const modalEl = useRef(null);

	// clicking outside the modal closes it when it's open, this prevents clicks from inside
	// the modal from bubbling up and closing the modal
	const stopPropagation = (event) => {
		event.stopPropagation();
	};

	// we use a ref on the parent div to check if it contains the click target,
	// if not (ie, user clicked outside the modal) we close the modal
	const handleOutsideModalClick = (e) => {

		if (modalEl.current && !modalEl.current.contains(e.target)) {

			overflowHidden(myListModalOpen);
			setMyListModalOpen(false);
			document.removeEventListener("click", handleOutsideModalClick);
		}
	};

	/// AXIOS REQUESTS

	// get lists that the user has already created
	const getLists = async () => {
		const listApiUrl = `${windowUrl}/${locale}/lists/`;

		try {
			const response = await Axios.get(listApiUrl);
			setIsLoading(false);
			if (response.data.success) {
				const responseObj = response.data.data;
				setUserLists(responseObj);
				setError(false);
			}
		} catch (err) {
			setIsLoading(false);
			setError(err.response.data.message);
		}
	};

	/// get users lists on load & add listener event to document

	useEffect(() => {
		document.addEventListener("click", handleOutsideModalClick);
		getLists();
		setIsLoading(true);
	}, []);


	return (
		<>
			{!isLoading && !error && userLists && (
				<>
					<div
						className="my-list__modal"
						ref={modalEl}
						onClick={stopPropagation}
					>
						<ul className="my-list__list">
							{userLists.map((list) => {
								return (
									<li key={list.id} className="list-item">
										<a href={windowUrl + '/' + locale + '/lists/' + list.id}>{list.title}</a>
									</li>
								);
							})}
						</ul>
						<button
							className="my-list__new-list-button"
							onClick={()=>{
								overflowHidden(createListModalOpen)
								setCreateListModalOpen(true)
								setMyListModalOpen(false);
								document.removeEventListener("click", handleOutsideModalClick);

							}}
						>{translations.list.create_list}</button>
					</div>


				</>
			)}
		</>
	);
};

export default MyListModal;

MyListModal.propTypes = {
	locale: PropTypes.string,
	myListModalOpen: PropTypes.bool,
	setMyListModalOpen: PropTypes.func,
	createListModalOpen: PropTypes.bool,
	seCreateListModalOpen: PropTypes.func,
	translations: PropTypes.object,
	setCreateListModalOpen: PropTypes.func
};
