import React from "react";
import ReactDOM from "react-dom";
import { useEffect, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import Axios from "axios";
import PropTypes from "prop-types";
import qs from "qs";

import SearchTerms from "./SearchTerms";
import Filters from "./Filters";
import SearchResultsList from "./SearchResultsList";
import AddToListModal from "../../lists/add/AddToListModal";
import getSearchData from "../../hooks/getSearchData";
import setPagination from "../../hooks/setPagination";

export default function SearchResultsPage({
	currentLocale,
	translationsString,
	arrowIcon,
}) {
	const translations = JSON.parse(translationsString);

	// get search params from url
	const urlSearchParams = new URLSearchParams(window.location.search);
	// save search params in object
	const searchParams = Object.fromEntries(urlSearchParams.entries());
	const windowUrl = window.origin;
	let searchUrl;

	// for filters object
	const typeKey = `type.${currentLocale}`;

	const [searchResults, setSearchResults] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [listModalOpen, setListModalOpen] = useState(false);
	const [docSelected, setDocSelected] = useState(null);

	// active filter value in each category
	const [collection, setCollection] = useState("");
	const [type, setType] = useState("");
	const [country, setCountry] = useState("");
	const [language, setLanguage] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");

	// active filter label in each category to display when selected so we can deselect filters
	const [collectionLabel, setCollectionLabel] = useState("");
	const [typeLabel, setTypeLabel] = useState("");
	const [countryLabel, setCountryLabel] = useState("");
	const [languageLabel, setLanguageLabel] = useState("");
	const [startDateLabel, setStartDateLabel] = useState("");
	const [endDateLabel, setEndDateLabel] = useState("");

	// filters object for URL containing al the filters above — gets populated in the useEffect
	const [filters, setFilters] = useState({});

	// filters array for search terms to clear active filter
	const activeFilter = [
		collectionLabel,
		typeLabel,
		countryLabel,
		languageLabel,
		startDateLabel,
		endDateLabel
	]

	// to clear active filter label
	const setActiveFilterLabel = [
		setCollectionLabel,
		setTypeLabel,
		setCountryLabel,
		setLanguageLabel,
		setStartDateLabel,
		setEndDateLabel
	]

	// to clear active filter
	const setActiveFilter = [
		setCollection,
		setType,
		setCountry,
		setLanguage,
		setStartDate,
		setEndDate
	]

	// pagination states
	const [totalResults, setTotalResults] = useState(null);
	const [pagesNumber, setPagesNumber] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);

	const isDesktop = useMediaQuery({ query: "(min-width: 1220px)" });

	useEffect(() => {

		// turn search params into object
		const parsedUrlParams = qs.parse(searchParams);

		// check if each url search params have a value on page load and assign it to the correct filter state
		if (parsedUrlParams.filters) {
			if (parsedUrlParams.filters.whereMatch[typeKey].length) {
				setType(parsedUrlParams.filters.whereMatch[typeKey]);
			}
			if (parsedUrlParams.filters.whereMatch.category_id.length) {
				setCollection(parsedUrlParams.filters.whereMatch.category_id);
			}
			if (parsedUrlParams.filters.whereMatch.country.length) {
				setCountry(parsedUrlParams.filters.whereMatch.country);
			}
			if (parsedUrlParams.filters.whereMatch.language.length) {
				setType(parsedUrlParams.filters.whereMatch.language);
			}
		}

		//* error if publication_date: [ "", "" ] so only add to object if start date exists (end date is set by default as today's date)
		const whereBetween = startDate.length ? {whereBetween: {publication_date: [startDate, endDate]}} : null //eslint-disable-line

		// create filters object and assign it correct values
		setFilters({
			...searchParams,
			filters: {
				whereMatch: {
					[typeKey]: type,
					category_id: collection, //eslint-disable-line
					country: country,
					language: language,
				},
				...whereBetween
			},
		});

		// run hook on each filter change and on page load
	}, [ collection, type, country, language, startDate, endDate, setFilters ]);

	const firstUpdate = useRef(true);

	useEffect(() => {

		// prevent axios call from running on page load before the filters object is built resulting in error message being displayed momentarily
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		if (Object.keys(searchParams).length) {
			searchUrl = `${windowUrl}/search/type?${qs.stringify(filters)}`;

			setLoading(true);

			getSearchData(
				setSearchResults,
				setTotalResults,
				setLoading,
				setError,
				setCurrentPage,
				setPagesNumber,
				searchUrl,
				totalResults
			);
		}
		// this useEffect runs once the other one resolves and the filter object is updated
	}, [filters]);

	useEffect(() => {

		setPagination(
			searchParams,
			setPagesNumber,
			setCurrentPage,
			totalResults
		);

	}, [totalResults])

	return (
		<>
			<div className="search-results">
				<SearchTerms
					translations={translations}
					searchParams={searchParams}
					activeFilter={activeFilter}
					setActiveFilter={setActiveFilter}
					setActiveFilterLabel={setActiveFilterLabel}
				/>
				<div className="main-grid">
					<div className="margin" />
					<div className="main-grid__body search-results__wrap">
						{isDesktop && (
							<Filters
								translations={translations}
								currentLocale={currentLocale}
								setFilters={setFilters}
								setCollection={setCollection}
								setType={setType}
								setCountry={setCountry}
								setLanguage={setLanguage}
								setStartDate={setStartDate}
								setEndDate={setEndDate}
								collection={collection}
								type={type}
								country={country}
								language={language}
								setCollectionLabel={setCollectionLabel}
								setTypeLabel={setTypeLabel}
								setCountryLabel={setCountryLabel}
								setLanguageLabel={setLanguageLabel}
								setStartDateLabel={setStartDateLabel}
								setEndDateLabel={setEndDateLabel}
								startDate={startDate}
								endDate={endDate}
							/>
						)}
						<SearchResultsList
							totalResults={totalResults}
							currentLocale={currentLocale}
							isDesktop={isDesktop}
							translations={translations}
							searchResults={searchResults}
							error={error}
							loading={loading}
							currentPage={currentPage}
							pagesNumber={pagesNumber}
							arrowIcon={arrowIcon}
							setFilters={setFilters}
							setCollection={setCollection}
							setType={setType}
							setCountry={setCountry}
							setLanguage={setLanguage}
							collection={collection}
							type={type}
							country={country}
							language={language}
							setStartDate={setStartDate}
							setEndDate={setEndDate}
							setCollectionLabel={setCollectionLabel}
							setTypeLabel={setTypeLabel}
							setCountryLabel={setCountryLabel}
							setLanguageLabel={setLanguageLabel}
							setStartDateLabel={setStartDateLabel}
							setEndDateLabel={setEndDateLabel}
							startDate={startDate}
							endDate={endDate}
							filters={filters}
							setListModalOpen={setListModalOpen}
							listModalOpen={listModalOpen}
							setDocSelected={setDocSelected}
							setSearchResults={setSearchResults}
							setTotalResults={setTotalResults}
							setLoading={setLoading}
							setError={setError}
							setCurrentPage={setCurrentPage}
							setPagesNumber={setPagesNumber}
							searchUrl={searchUrl}
							searchParams={searchParams}
						/>
					</div>
				</div>
        	{listModalOpen &&  docSelected &&(
					<AddToListModal
						listModalOpen={listModalOpen}
						setListModalOpen={setListModalOpen}
						locale={currentLocale}
						docSelected={docSelected}
						translations={translations}
					/>
				)}
			</div>
		</>
	);
}

if (document.getElementById("searchResultsPage")) {
	const searchResultsTag = document.getElementById("searchResultsPage");
	ReactDOM.render(
		<SearchResultsPage {...searchResultsTag.dataset} />,
		document.getElementById("searchResultsPage")
	);
}

SearchResultsPage.propTypes = {
	currentLocale: PropTypes.string.isRequired,
	translationsString: PropTypes.string.isRequired,
	arrowIcon: PropTypes.string.isRequired
};
