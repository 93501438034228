import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import moment from "moment";

export default function DateFilter({
	title,
	setStartDate,
	setEndDate,
	startDate,
	endDate,
	translations
}) {

	const [isOpen, setIsOpen] = useState(false);
	const today = moment(new Date).format('YYYY-MM-DD');

	useEffect(() => {
		// set the default end date as today's date
		setEndDate(today)
	}, [])

	return (
		<div className={`filter filter--date ${isOpen && "open"}`}>
			<div
				className="filter__title"
				tabIndex="0"
				onClick={() => setIsOpen(!isOpen)}
			>
				<p>{title}</p>
				{isOpen ? <span>&minus;</span> : <span>&#43;</span>}
			</div>
			<div className="filter__list">
				<label htmlFor="start">{translations.search.startDate}</label>

				<input
					className="filter__list__item--date"
					type="date"
					id="start"
					name="trip-start"
					value={startDate}
					onChange={(event) => {
						setStartDate(event.target.value);
					}}
				/>

				<label htmlFor="end">{translations.search.endDate}</label>

				<input
					className="filter__list__item--date"
					type="date"
					id="end"
					name="trip-start"
					value={endDate}
					max={today}
					onChange={(event) => {
						setEndDate(event.target.value);
					}}
				/>
			</div>
		</div>
	);
}

DateFilter.propTypes = {
	title: PropTypes.string.isRequired,
	setStartDate: PropTypes.func.isRequired,
	setEndDate: PropTypes.func.isRequired,
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired
};
