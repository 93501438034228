import Axios from "axios";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { PropTypes, oneOfType } from "prop-types";
import CollectionCard from "./CollectionCard";

const Collections = ({ currentLocale, translationsString, userString }) => {

    const windowUrl = window.origin;
    const translations = JSON.parse(translationsString);

    // states
    const [locale, setLocale] = useState("");
    const [collections, setCollections] = useState(null);
    const [error, setError] = useState(null);

    const getCollections = async () => {
        /// get collections from API

        const collectionsApiUrl = `${windowUrl}/${locale}/collections`;
        const response = await Axios.get(collectionsApiUrl);
        if (response.data.success) {
            setCollections(response.data.data);
            setError(false);
        } else {
            setError(true);
        }
    };

    useEffect(() => {
        setLocale(currentLocale);

        if (locale) {
            getCollections().catch(() => {
                setError(true);
            });
            // wait until we have locale to run get collections
        }
    }, [locale]);

    const collectionCurtain = () => {
        if (userString) return null;
        else {
            return (
                <div className="collections__curtain">
                    <div className="message">
                        {" "}
                        {translations.documents.no_auth}{" "}
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            {collectionCurtain()}
            {!error && collections && (
                <section
                    className="main-grid collections"
                    aria-label="Collections"
                >
                    <div className="main-grid__margin"></div>
                    <div className="main-grid__body">
                        <h2 className="collections__title">
                            {translations.documents.collections}
                        </h2>
                        <div className="cards-grid">
                            {collections.map((collection) => {
                                return (
                                    <CollectionCard
                                        key={collection.id}
                                        collection={collection}
                                        locale={locale}
                                        windowUrl={windowUrl}
                                        translations={translations}
                                        userString={userString}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="main-grid__margin"></div>
                </section>
            )}
        </>
    );
};

export default Collections;

Collections.propTypes = {
    currentLocale: PropTypes.string,
    translationsString: PropTypes.string,
	userString:oneOfType([
		PropTypes.string,
		PropTypes.object
	  ]),
};

if (document.getElementById("collections")) {
    const collectionsElement = document.getElementById("collections");
    ReactDOM.render(
        <Collections {...collectionsElement.dataset} />,
        collectionsElement
    );
}
