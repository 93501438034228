import React from "react";
import {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import { useMediaQuery } from "react-responsive";
import PropTypes from "prop-types";

import MobileView from "./Mobile/MobileView";
import DesktopView from "./Desktop/DesktopView";
import MyListModal from "../lists/userLists/MyListsModal";
import CreateListModal from '../lists/create/CreateListModal';

export default function Header({currentLocale, csrf, userString, translationsString}) {

	// csrf is a token imported from laravel, it is a string of random numbers and letters and gets sent with authentication queries for security

	const isDesktop = useMediaQuery({ query: "(min-width: 768px)" });
	const translations = JSON.parse(translationsString);

	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);
	const [user, setUser] = useState(null);
	const [myListModalOpen, setMyListModalOpen] = useState(false);
	const [createListModalOpen, setCreateListModalOpen] = useState(false);

	useEffect(() => {
		// the user is imported as a JSON string, if the string has a length then we parse it into an object and set the user
		if (userString.length) {
			setIsLoggedIn(true)
			setUser(JSON.parse(userString))
		} else {
			setIsLoggedIn(false)
			setUser(null)
		}
	}, [isLoggedIn])

	return (
		<div className="header">
			<div className="main-grid">
				<div className="margin"></div>
				<div className="header__body">
					{isDesktop ? (
						<DesktopView
							csrf={csrf}
							isDesktop={isDesktop}
							isLoggedIn={isLoggedIn}
							locale={currentLocale}
							setIsLoggedIn={setIsLoggedIn}
							user={user}
							translations={translations}
							setMyListModalOpen={setMyListModalOpen}
							myListModalOpen={myListModalOpen}
						/>
					) : (
						<MobileView
							csrf={csrf}
							isDesktop={isDesktop}
							isLoggedIn={isLoggedIn}
							locale={currentLocale}
							menuOpen={menuOpen}
							setIsLoggedIn={setIsLoggedIn}
							setMenuOpen={setMenuOpen}
							user={user}
							translations={translations}
						/>
					)}
					{myListModalOpen && (
						<MyListModal
							locale={currentLocale}
							myListModalOpen={myListModalOpen}
							setMyListModalOpen={setMyListModalOpen}
							createListModalOpen={createListModalOpen}
							setCreateListModalOpen={setCreateListModalOpen}
							translations={translations}
						/>
					)}
					{createListModalOpen &&  (
						<CreateListModal
							createListModalOpen={createListModalOpen}
							setCreateListModalOpen={setCreateListModalOpen}
							locale={currentLocale}
							translations={translations}

						/>
					)}
				</div>
				<div className="margin"></div>
			</div>
		</div>
	);
}

Header.propTypes = {
	currentLocale: PropTypes.string.isRequired,
	csrf: PropTypes.string.isRequired,
	userString: PropTypes.string,
	translationsString: PropTypes.string.isRequired
}

if (document.getElementById("header")) {
	const headerTag = document.getElementById('header')
	ReactDOM.render(<Header {...headerTag.dataset} />, document.getElementById("header"));
}

