import React from "react";
import PropTypes from "prop-types";
import overflowHidden from "../hooks/overflowHidden";

const MainDocument = ({document, locale, translations, setListModalOpen, listModalOpen}) => {

	const getPublicationDate = () => {
		const date = new Date(document.publication_date).toLocaleDateString(
			`${locale + "-ca"}`,
			{
				year: "numeric",
				month: "long",
				day: "numeric",
				timeZone: "utc",
			}
		);
		return date;
	};

	const createMarkup =(text)=>{
		return {__html: text}
	}


	return (
		<section
			className="main-grid document-view"
			aria-labelledby="document-title"
		>
			<div className="main-grid__margin"></div>
			<div className="main-grid__body">
				<div className="content-body">
					<h1 id="document-title">
						{document.title[locale]? document.title[locale]: document.title[document.language]}
					</h1>
					<div className="button-wrap">
						{/* // download document */}
						<a
							className="button--download--large"
							href={
								window.location.origin +
                                "/" +
                                locale +
                                "/download/documents/" +
                                document.id
							}
							target="_blank" rel="noreferrer"

						>
							{translations.documents.download}
						</a>
						{/* // add to list */}
						<button className="button--list--large"
							onClick={()=>{
								overflowHidden(listModalOpen)
								setListModalOpen(true)
							}}
						>
							{translations.documents.add_to_list}
						</button>
					</div>
					{/* document abstract */}
					{document.abstract && (
						<p className="document-view__abstract"

							dangerouslySetInnerHTML={createMarkup(document.abstract[locale]? document.abstract[locale]: document.abstract[document.language]) }
						>
						</p>
					)}
					{/* document details */}
					<div className="document-view__details">
						{document.category.title[document.language] && (
							<>
								<h4>{translations.documents.categories}</h4>
								<div>
									{document.category.title[locale]? document.category.title[locale]: document.category.title[document.language]}
								</div>
							</>
						)}

						{document.source && (
							<>
								<h4>{translations.documents.source}</h4>
								<div>{document.source[ locale ]? document.source[ locale ]: document.source[ document.language ]}</div>
							</>
						)}
						{document.type && (
							<>
								<h4>{translations.documents.document_type}</h4>
								<div>{document.type[locale ]? document.type[ locale ]: document.type[ document.language ]}</div>
							</>
						)}
						{document.conventions && (
							<>
								<h4>{translations.documents.conventions}</h4>
								<div>{document.conventions[locale ]? document.conventions[locale ]: document.conventions[ document.language ]}</div>
							</>
						)}
						{document.tribunal && (
							<>
								<h4>{translations.documents.tribunal}</h4>
								<div>{document.tribunal[ locale ]? document.tribunal[ locale ]: document.tribunal[ document.language ]}</div>
							</>
						)}
						{document.country && (
							<>
								<h4>{translations.documents.country}</h4>
								<div>{document.country}</div>
							</>
						)}

						<h4>{translations.documents.language}</h4>
						<div>{document.language}</div>
						{document.publication_date && (
							<>
								<h4>{translations.documents.date_of_publication}</h4>
								<div>{getPublicationDate()}</div>
							</>
						)}
						{document.keywords && (
							<>
								<h4>{translations.documents.keywords}</h4>
								<div>{document.keywords[ locale]? document.keywords[ locale] : document.keywords[ document.language ]}</div>
							</>
						)}
					</div>
				</div>
			</div>
			<div className="main-grid__margin"></div>
		</section>
	);
};

MainDocument.propTypes = {
	document: PropTypes.object,
	locale: PropTypes.string,
	translations: PropTypes.object,
	setListModalOpen: PropTypes.func,
	listModalOpen: PropTypes.bool,

}

export default MainDocument;
