import Axios from "axios";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import MainDocument from "./MainDocument";
import RelatedDocuments from "./RelatedDocuments";
import AddToListModal from '../lists/add/AddToListModal';

const DocumentView = ( {currentLocale, translationsString }) => {


	// states
	const [locale, setLocale] = useState("");
	const [document, setDocument] = useState(null);
	const [error, setError] = useState(false);
	const [listModalOpen, setListModalOpen] = useState(false);

	const translations = JSON.parse(translationsString)

	const getData = async () => {
		/// get documents & collection from API for this collection

		const documentApiUrl = window.location.href+"?ajax=1";
		const response = await Axios.get(documentApiUrl);

		if (response.data.success) {
			setDocument(response.data.data);
			setError(false);
		}
	};

	useEffect(() => {
		setLocale(currentLocale);

		if (locale) {
			// wait until we have locale to run get documents
			getData().catch((error) => {
				setError(error.response.data.message);
			});
		}
	}, [locale]);

	return (
		<>
			{error && (
				<div className="document-view__error">
					{error}
				</div>
			)}
			{!error && document && (
				<>
					<MainDocument
						document={document}
						locale={locale}
						translations={translations}
						setListModalOpen={setListModalOpen}
						listModalOpen={listModalOpen}

					/>
					{listModalOpen && (
						<AddToListModal
							listModalOpen={listModalOpen}
							setListModalOpen={setListModalOpen}
							locale={locale}
							docSelected={document.id}
							translations={translations}
						/>
					)}
					{document.related.length > 0  && (
						<RelatedDocuments
							related={document.related}
							locale={locale}
							translations={translations}
							setListModalOpen={setListModalOpen}
							listModalOpen={listModalOpen}
						/>

					)}
					<div className="documents__wrap__button-wrap button-wrap--center">
						<a href={window.location.origin + '/' + locale + '/documents?collection=' + document.category_id }
							className="button">{translations.documents.view_all_documents}
						</a>
					</div>


				</>

			)}
		</>
	);
};

export default DocumentView;

DocumentView.propTypes = {
	currentLocale: PropTypes.string,
	translationsString: PropTypes.string
}

if (document.getElementById("document-view")) {
	const documentViewElement = document.getElementById("document-view");
	ReactDOM.render(
		<DocumentView {...documentViewElement.dataset} />,
		documentViewElement
	);
}
