const setPagination = (searchParams, setPagesNumber, setCurrentPage, totalResults) => {
	// if page number in url query set current page number
	if (searchParams.page.length) {
		setCurrentPage(parseInt(searchParams.page));
	}

	// set up pagination
	if (totalResults > 12) {
		// round to next larger integer
		setPagesNumber(Math.ceil(totalResults / 12));
	} else {
		// on filter change set pages number to 1 if less than 12 results
		setPagesNumber(1);
	}
}

export default setPagination;
