import PropTypes from "prop-types";
import React, { useState } from "react";
import overflowHidden from "../../hooks/overflowHidden";
import Axios from "axios";
import ModalLoading from "../../ModalLoading";
import CreateListForm from "./CreateListForm";

// Create a list Modal is launched from the MyListModal only

const CreateListModal = ({
	locale,
	createListModalOpen,
	setCreateListModalOpen,
	translations
}) => {
	const windowUrl = window.origin;

	//state
	const [error, setError] = useState(false);
	const [newList, setNewList] = useState("");
	const [isCreated, setIsCreated] = useState(false);
	const [isLoading, setIsLoading] = useState(null);

	// functions that handle state so we can pass them to the children & they can update state

	const updateNewList = (list) => {
		setNewList(list);
	};

	// closeModal in a function so we can call it set timeout

	const closeModal = () => {
		overflowHidden(createListModalOpen);
		setCreateListModalOpen(false);
	};

	// clicking outside the modal closes it when it's open, this prevents clicks from inside
	// the modal from bubbling up and closing the modal
	const stopPropagation = (event) => {
		event.stopPropagation();
	};

	/// AXIOS REQUESTS

	// create a new list & add selected doc to this list
	const addNewList = async (listName) => {
		const listsApiUrl = `${windowUrl}/${locale}/lists`;

		try {
			const response = await Axios.post(listsApiUrl, { title: listName });
			if (response.data.success) {
				setNewList("");
				setError(false);
				setIsLoading(false)
				setIsCreated(true)
				setTimeout(closeModal, 500)
			}
		} catch (err) {
			setIsLoading(false);
			setError(err.response.status + ": " + err.response.statusText);
		}
	};


	return (
		<div className="list-modal">
			<div className="list-modal__overlay" onClick={closeModal}>
				<div
					className="list-modal__modal modal"
					onClick={stopPropagation}
				>
					<div className="modal__wrap">
						<div className="modal__close" onClick={closeModal}>
							<span className="modal__close--line" />
							<span className="modal__close--line" />
						</div>
						<div className="modal__text">
							<h2>{translations.list.create_a_list}</h2>
							<p>{translations.list.create_text} </p>
						</div>
						{isLoading && (
							<>
								<ModalLoading />
							</>
						)}

						{error && (
							<div className="modal__error">
								<p>{error}</p>
							</div>
						)}

						{!isLoading && !error &&  (
							<>
								<CreateListForm
									updateNewList={updateNewList}
									newList={newList}
									addNewList={addNewList}
									setIsLoading={setIsLoading}
									isCreated={isCreated}
									translations={translations}

								/>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateListModal;

CreateListModal.propTypes = {
	locale: PropTypes.string,
	createListModalOpen: PropTypes.bool,
	setCreateListModalOpen: PropTypes.func,
	translations: PropTypes.object
};
