import React from "react";
import PropTypes from "prop-types";

export default function SearchTerms({
	translations,
	searchParams,
	activeFilter,
	setActiveFilter,
	setActiveFilterLabel
}) {
	return (
		<div className="search-terms main-grid">
			<div className="margin" />
			<div className="main-grid__body">
				<h2>{`${translations.search.searchResults} "${searchParams?.search}"`}</h2>
				<div className="filters">
					{activeFilter.map((filter, index) => {
						if (filter.length) {
							return (
								<div
									className="filter"
									onClick={() => {
										// clear label and filter
										setActiveFilterLabel[index]("");
										setActiveFilter[index]("");
									}}
								>
									<p>{filter}</p>
									<div className="filter__remove">
										<span></span>
										<span></span>
									</div>
								</div>
							);
						}
					})}
				</div>
			</div>
			<div className="margin" />
		</div>
	);
}

SearchTerms.propTypes = {
	translations: PropTypes.instanceOf(Object).isRequired,
	searchParams: PropTypes.instanceOf(Object),
	activeFilter: PropTypes.instanceOf(Array).isRequired,
	setActiveFilter: PropTypes.instanceOf(Array).isRequired,
	setActiveFilterLabel: PropTypes.instanceOf(Array).isRequired,
};
