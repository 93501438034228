import React from "react";
import Axios from "axios";
import { useState } from "react";
import PropTypes from "prop-types";

import useInput from "../../hooks/useInput";
import overflowHidden from "../../hooks/overflowHidden";
import ModalLoading from "../../ModalLoading";

const ResetPasswordModal = ({
    setResetPasswordOpen,
    resetPasswordOpen,
    translations,
    csrf,
    setLoginOpen,
    setRegisterOpen,
}) => {
    const email = useInput("");
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resetMessage, setResetMessage] = useState(false);
    const windowUrl = window.origin;
    const resetPasswordApiUrl = `${windowUrl}/password/email`;

    // clicking outside the modal closes it when it's open, this prevents clicks from inside the modal from bubbling up and closing the modal
    const stopPropagation = (event) => {
        event.stopPropagation();
    };

    const closeModal = () => {
        setResetPasswordOpen(false);
    };

    const handleFormSubmit = () => {
        setLoading(true);
        setError(null);
        const formBody = {
            email: email.value,
            _token: csrf,
        };

        // https://developer.mozilla.org/en-US/docs/Web/API/FormData
        const formData = new FormData();

        for (const field in formBody) {
            formData.append(field, formBody[field]);
        }
        return Axios.post(resetPasswordApiUrl, formData)
            .then(() => {
                console.log("success");
                setLoading(false);
                setResetMessage(true);
                setTimeout(closeModal, 2000);
            })
            .catch((error) => {
                if (error.response) {
                    setLoading(false);
                    if (error.response.data.errors) {
                        setError(error.response.data.errors.email);
                    } else {
                        setError(error.response.statusText);
                    }
                }
            });
    };

    const renderModalText = () => {
        if (!resetMessage) {
            return (
                <>
                    <h2> {translations.forgotPasswordQuestion}</h2>
                    <p>{translations.enterEmail}</p>
                </>
            );
        } else {
            return (
                <>
                    <h2> {translations.linkSent}</h2>
                    <p>{translations.linkSentFull}</p>
                </>
            );
        }
    };

    return (
        <div className="login">
            <div
                className="login__overlay"
                onClick={() => {
                    overflowHidden(resetPasswordOpen);
                    setResetPasswordOpen(false);
                }}
            >
                <div className="login__modal modal" onClick={stopPropagation}>
                    <div className="modal__wrap">
                        <div
                            className="modal__close"
                            onClick={() => {
                                overflowHidden(resetPasswordOpen);
                                setResetPasswordOpen(false);
                            }}
                        >
                            <span className="modal__close--line" />
                            <span className="modal__close--line" />
                        </div>
                        <div className="modal__text">{renderModalText()}</div>
                        {loading && <ModalLoading />}

                        {!loading && !resetMessage && (
                            <>
                                <form
                                    className="modal__form"
                                    onSubmit={(event) => {
                                        event.preventDefault();
                                        handleFormSubmit();
                                    }}
                                >
                                    <div className="modal__form--input">
                                        <input
                                            type="text"
                                            id="login__email"
                                            required
                                            {...email}
                                        />
                                        <label htmlFor="login__email">
                                            {translations.email}
                                        </label>
                                    </div>

                                    {error && (
                                        <div className="modal__error">
                                            <p>{error}</p>
                                        </div>
                                    )}
                                    <div className="modal__form--submit">
                                        <button type="submit">
                                            {translations.sendLink}
                                        </button>
                                    </div>
                                </form>
                                <div className="modal__text--center">
                                    <p>
                                        <span
                                            className="link"
                                            onClick={() => {
                                                setLoginOpen(true);
                                                setRegisterOpen(false);
                                                setResetPasswordOpen(false);
                                            }}
                                        >
                                            {translations.backToLogin}
                                        </span>
                                    </p>
                                    <p>
                                        <span
                                            className="link"
                                            onClick={() => {
                                                setLoginOpen(false);
                                                setRegisterOpen(true);
                                                setResetPasswordOpen(false);
                                            }}
                                        >
                                            {translations.registerNew}
                                        </span>
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ResetPasswordModal;

ResetPasswordModal.propTypes = {
    setResetPasswordOpen: PropTypes.func.isRequired,
    resetPasswordOpen: PropTypes.bool.isRequired,
    setLoginOpen: PropTypes.func.isRequired,
    setRegisterOpen: PropTypes.func.isRequired,
    translations: PropTypes.instanceOf(Object).isRequired,
    csrf: PropTypes.string.isRequired,
};
