import React from 'react';
import { useState, useRef, useEffect } from 'react';
import PropTypes from "prop-types";

import useInput from "../hooks/useInput";

export default function Search({isDesktop, translations, locale}) {

	const [searchOpen, setSearchOpen] = useState(false)
	const searchTerms = useInput("");
	const windowUrl = window.origin;
	const searchUrl = `${windowUrl}/${locale}/search?type=document&search=`;

	// custom Hook that closes mobile search on clicks outside of the passed ref (search form)
	const closeSearchOnClickOutside = (ref) => {

		useEffect(() => {
			const handleClickOutside = (event) => {
				// check if ref exists and if the click is outside of it
				if (ref.current && !ref.current.contains(event.target)) {
					setSearchOpen(false)
				}
			}

			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref])
	}

	const wrapperRef = useRef(null);
	closeSearchOnClickOutside(wrapperRef);

	const submitSearch = (event) => {
		event.preventDefault();

		// redirect to search result page and pass search terms as url parameters to search there on page load
		window.location.href = searchUrl + searchTerms.value + "&page=1";
	}

	if (isDesktop) {
		return (
			<div className="header__search">
				<div className="search__wrap">
					<form className="search__form" ref={wrapperRef} onSubmit={(e) => submitSearch(e)}>
						<input className="search__field" type="text" id="header-search" required {...searchTerms} />
						<label className="search__label" htmlFor="header-search">{translations.search}</label>
						<button className="search__submit" type="submit" />
					</form>
				</div>
			</div>
		)
	}

	return (
		<div className={`header__search header__search--mobile ${searchOpen && 'search--open'}`}>
			<div className="search__wrap">
				<div className="search__mobile">
					{searchOpen ? (
						<form className="search__form" ref={wrapperRef} onSubmit={(e) => submitSearch(e)}>
							<input className="search__field" type="text" id="header-search" required autoFocus {...searchTerms} />
							<label className="search__label" htmlFor="header-search">{translations.search}</label>
							<button className="search__submit" type="submit" />
						</form>
					) : (
						<button className="search__open" onClick={() => setSearchOpen(true)} />
					)}
				</div>
			</div>
		</div>
	)
}

Search.propTypes = {
	isDesktop: PropTypes.bool,
	locale: PropTypes.string.isRequired,
	translations: PropTypes.instanceOf(Object).isRequired
}
