import React from "react";
import PropTypes from "prop-types";

import Search from "../Search"
import TextSizeButtons from "../TextSizeButtons";
import Auth from "../Auth/Auth";
import overflowHidden from "../../hooks/overflowHidden";
export default function DesktopView({
	isLoggedIn,
	isDesktop,
	locale,
	csrf,
	setIsLoggedIn,
	user,
	translations,
	myListModalOpen,
	setMyListModalOpen}) {



	return (
		<>
			<div className="header__logo">
				<a href="/">
					<img
						src="../../../images/png/McGill-LLDRD.png"
						alt="McGill LLDRD logo"
					/>
				</a>
			</div>
			<div className="header__info">
				<div className="info__top">
					<Auth
						csrf={csrf}
						isLoggedIn={isLoggedIn}
						locale={locale}
						setIsLoggedIn={setIsLoggedIn}
						user={user}
						translations={translations}
					/>
					<div className="language-toggle">
						{locale === "en" ? (
							<a className="menu-item" href="/fr">
								{translations.locale}
							</a>
						) : (
							<a className="menu-item" href="/en">
								{translations.locale}
							</a>
						)}
					</div>
					<div className="text-sizes" id="text-sizes">
						<TextSizeButtons />
					</div>
				</div>
				<div className="header__bottom">
					<div className="header__lists">
						{isLoggedIn && (
							<button className="menu-item my-list__button"
								onClick={()=>{
									overflowHidden(myListModalOpen);
									setMyListModalOpen(true);
								}
								}>
								{translations.myLists}
							</button>
						)}
					</div>
					<Search
						isDesktop={isDesktop}
						locale={locale}
						translations={translations}
					/>
				</div>
			</div>
		</>
	);
}

DesktopView.propTypes = {
	isLoggedIn: PropTypes.bool.isRequired,
	isDesktop: PropTypes.bool.isRequired,
	locale: PropTypes.string.isRequired,
	csrf: PropTypes.string.isRequired,
	setIsLoggedIn: PropTypes.func.isRequired,
	user: PropTypes.instanceOf(Object),
	translations: PropTypes.instanceOf(Object).isRequired,
	myListModalOpen: PropTypes.bool.isRequired,
	setMyListModalOpen: PropTypes.func.isRequired
};
