import React from "react";
import PropTypes from "prop-types";

// The form that appears on the Add To List Modal

const AddToListForm = ({
	userLists,
	addNewList,
	newList,
	updateNewList,
	updateSelectedList,
	selectedList,
	addDocToList,
	docSelected,
	setIsLoading,
	translations,
	isAdded
}) => {
	// EVENT HANDLERS

	// get input from text input, clear dropdown
	const handleInputChange = (e) => {
		document.getElementById("user__lists").value = "";
		updateNewList(e.target.value);
		updateSelectedList("");
	};
	// get the selected list from the dropdown, clear text input
	const handleSelectChange = (e) => {
		updateSelectedList(e.target.value);
		updateNewList("");
	};

	// submit
	const handleFormSubmit = (e) => {
		// Prevents the page refreshing
		e.preventDefault();
		setIsLoading(true);

		if (newList) {
			addNewList(newList);
		} else if (selectedList) {
			addDocToList(selectedList, docSelected);
		}
	};

	return (
		<form className="modal__form" onSubmit={handleFormSubmit}>
			<div className="modal__form--input select-wrapper-arrow">
				<select
					type="text"
					id="user__lists"
					onChange={handleSelectChange}
				>
					<option value="">&mdash; &nbsp; {translations.list.add_to_list}&nbsp;  &mdash;</option>
					{userLists.map((list) => {
						return (
							<option
								value={list.id}
								key={list.id}
								onChange={handleSelectChange}
							>
								{list.title}
							</option>
						);
					})}
				</select>
			</div>
			<div className="align--center"> &minus;{translations.list.or}&minus;</div>
			<div className="modal__form--input">
				<input
					type="text"
					id="list__title"
					onChange={handleInputChange}
					value={newList}
				/>
				<label
					htmlFor="list__title"
					className={newList ? "input-has-value" : null}
				>
					{translations.list.new_list}
				</label>
			</div>

			<div className="modal__form--submit">
				<button type="submit" className={isAdded? 'button--added': null}>{isAdded? translations.list.added : translations.list.add}</button>
			</div>
		</form>
	);
};

export default AddToListForm;
AddToListForm.propTypes = {
	userLists: PropTypes.array,
	addNewList: PropTypes.func,
	newList: PropTypes.string,
	updateNewList: PropTypes.func,
	updateSelectedList: PropTypes.func,
	selectedList: PropTypes.string,
	addDocToList: PropTypes.func,
	docSelected: PropTypes.string,
	setIsLoading: PropTypes.func,
	isAdded: PropTypes.bool,
	translations: PropTypes.object
};
