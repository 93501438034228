import PropTypes from "prop-types";
import React, { useState , useEffect} from 'react';
import overflowHidden from '../../hooks/overflowHidden';
import AddToListForm from "./AddToListForm";
import Axios from 'axios';
import ModalLoading from "../../ModalLoading";

// The card that is seen on the Documents Index

const  AddToListModal = ({locale, listModalOpen, setListModalOpen, docSelected, translations}) =>{

	const windowUrl = window.origin;

	//state
	const [userLists, setUserLists] = useState(null);
	const [error, setError] = useState(false);
	const [newList, setNewList] = useState("");
	const [selectedList, setSelectedList ] = useState("");
	const [isLoading, setIsLoading] = useState(null);
	const [isAdded, setIsAdded] = useState(false);



	// functions that handle state so we can pass them to the children & they can update state

	const updateNewList =(list)=>{
		setNewList(list)
	}
	const updateSelectedList =(list)=>{
		setSelectedList(list)
	}

	// closeModal in a function so we can call it set timeout

	const closeModal =()=>{
		overflowHidden(listModalOpen)
		setListModalOpen(false)
	}

	// clicking outside the modal closes it when it's open, this prevents clicks from inside
	// the modal from bubbling up and closing the modal
	const stopPropagation = (event) => {
		event.stopPropagation();
	}


	/// AXIOS REQUESTS

	// get lists that the user has already created
	const  getLists = async () =>{

		const listApiUrl = `${windowUrl}/${locale}/lists/`;

		try {
			const response = await Axios.get(listApiUrl);
			setIsLoading(false)
			if( response.data.success){
				const responseObj = response.data.data
				setUserLists(responseObj)
				setError(false)
			}
		} catch(err){
			setIsLoading(false)
			setError(err.response.data.message);
		}
	}


	// create a new list & add selected doc to this list
	const addNewList = async (listName) => {


		const listsApiUrl = `${windowUrl}/${locale}/lists`;

		try {

			const response = await Axios.post(listsApiUrl, {title: listName } )

			if (response.data.success){
				// get new list Id and immediately add the doc to this list
				const newListId = response.data.data.id
				addDocToList(newListId, docSelected)
				setNewList("")
				setError(false)

			}
		} catch (err){
			setIsLoading(false)
			setError(err.response.data.message);
			setTimeout(closeModal, 750)
		}
	};

	// add document to a selected list

	const addDocToList = async (listId, docId) =>{

		const selectedListApiUrl = `${windowUrl}/${locale}/lists/${listId}`;

		try {
			const response = await Axios.patch(selectedListApiUrl, {documents: [docId]})


			if (response.data.success){
				setIsLoading(false)
				setIsAdded(true)
				setError(false)
				setTimeout(closeModal, 750)
			}

		}catch(err){
			setIsLoading(false)
			setError(err.response.data.message);
			setTimeout(closeModal, 750)

		}

	}

	/// get users lists on load

	useEffect(() => {
		getLists()
		setIsLoading(true);
	}, []);


	return (
		<div className="list-modal">
			<div
				className="list-modal__overlay"
				onClick={() => {
					overflowHidden(listModalOpen)
					setListModalOpen(false)
				}}
			>
				<div className="list-modal__modal modal" onClick={stopPropagation}>
					<div className="modal__wrap">
						<div
							className="modal__close"
							onClick={() => {
								overflowHidden(listModalOpen)
								setListModalOpen(false)
							}}
						>
							<span className="modal__close--line" />
							<span className="modal__close--line" />
						</div>
						<div className="modal__text">
							<h2>{translations.list.add_to_list}</h2>
							<p>{translations.list.add_text} </p>

						</div>
						{isLoading && (
							<>
								<ModalLoading/>
							</>

						)}

						{error &&(
							<div className="modal__error">
								<p>{error}</p>
							</div>
						)}

						{!isLoading && !error && userLists &&(
							<>

								<AddToListForm
									userLists={userLists}
									addNewList={addNewList}
									newList={newList}
									updateNewList={updateNewList}
									updateSelectedList={updateSelectedList}
									selectedList={selectedList}
									addDocToList={addDocToList}
									docSelected={docSelected}
									setIsLoading={setIsLoading}
									translations={translations}
									isAdded={isAdded}

								/>


							</>

						)}



					</div>
				</div>
			</div>
		</div>
	);
}

export default AddToListModal;

AddToListModal.propTypes = {
	locale: PropTypes.string,
	listModalOpen: PropTypes.bool,
	setListModalOpen: PropTypes.func,
	docSelected: PropTypes.string,
	translations: PropTypes.object

}

