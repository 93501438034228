import React from "react";
import PropTypes from "prop-types";

import Filters from "./Filters"
import overflowHidden from "../../hooks/overflowHidden"

export default function MobileFilters({
	translations,
	mobileFiltersOpen,
	setMobileFiltersOpen,
	currentLocale,
	setFilters,
	collection,
	type,
	country,
	language,
	setCollection,
	setType,
	setCountry,
	setLanguage,
	setStartDate,
	setEndDate,
	setCollectionLabel,
	setTypeLabel,
	setCountryLabel,
	setLanguageLabel,
	setStartDateLabel,
	setEndDateLabel,
	startDate,
	endDate,
}) {
	// clicking outside the modal closes it when it's open, this prevents clicks from inside the modal from bubbling up and closing the modal
	const stopPropagation = (event) => {
		event.stopPropagation();
	};

	return (
		<div className="mobile-filters">
			<div
				className="mobile-filters__overlay"
				onClick={() => {
					overflowHidden(mobileFiltersOpen);
					setMobileFiltersOpen(false);
				}}
			>
				<div
					className="mobile-filters__modal modal"
					onClick={stopPropagation}
				>
					<div className="modal__wrap">
						<div
							className="modal__close"
							onClick={() => {
								overflowHidden(mobileFiltersOpen);
								setMobileFiltersOpen(false);
							}}
						>
							<span className="modal__close--line" />
							<span className="modal__close--line" />
						</div>
						<Filters
							translations={translations}
							currentLocale={currentLocale}
							setFilters={setFilters}
							collection={collection}
							type={type}
							country={country}
							language={language}
							setCollection={setCollection}
							setType={setType}
							setCountry={setCountry}
							setLanguage={setLanguage}
							setStartDate={setStartDate}
							setEndDate={setEndDate}
							setCollectionLabel={setCollectionLabel}
							setTypeLabel={setTypeLabel}
							setCountryLabel={setCountryLabel}
							setLanguageLabel={setLanguageLabel}
							setStartDateLabel={setStartDateLabel}
							setEndDateLabel={setEndDateLabel}
							startDate={startDate}
							endDate={endDate}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

MobileFilters.propTypes = {
	translations: PropTypes.instanceOf(Object).isRequired,
	mobileFiltersOpen: PropTypes.bool.isRequired,
	setMobileFiltersOpen: PropTypes.func.isRequired,
	currentLocale: PropTypes.string.isRequired,
	setFilters: PropTypes.func.isRequired,
	setCollection: PropTypes.func.isRequired,
	setType: PropTypes.func.isRequired,
	setCountry: PropTypes.func.isRequired,
	setLanguage: PropTypes.func.isRequired,
	setStartDate: PropTypes.func.isRequired,
	setEndDate: PropTypes.func.isRequired,
	setCollectionLabel: PropTypes.func.isRequired,
	setTypeLabel: PropTypes.func.isRequired,
	setCountryLabel: PropTypes.func.isRequired,
	setLanguageLabel: PropTypes.func.isRequired,
	setStartDateLabel: PropTypes.func.isRequired,
	setEndDateLabel: PropTypes.func.isRequired,
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired,
};
