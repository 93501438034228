import React from "react";
import PropTypes from "prop-types";

const CreateListForm = ({
	addNewList,
	newList,
	updateNewList,
	setIsLoading,
	translations,
	isCreated
}) => {
	// EVENT HANDLERS

	// get input from text input, clear dropdown
	const handleInputChange = (e) => {
		updateNewList(e.target.value);
	};

	// submit
	const handleFormSubmit = (e) => {
		// Prevents the page refreshing
		e.preventDefault();
		setIsLoading(true);

		if (newList) {
			addNewList(newList);
		}
	};

	return (
		<form className="modal__form" onSubmit={handleFormSubmit}>
			<div className="modal__form--input">
				<input
					type="text"
					id="list__title"
					onChange={handleInputChange}
					value={newList}
				/>
				<label
					htmlFor="list__title"
					className={newList ? "input-has-value" : null}
				>
					{translations.list.create_a_list}
				</label>
			</div>
			<div className="modal__form--submit">
				<button type="submit" className={isCreated? 'button--added': null}>{isCreated? translations.list.created: translations.list.create}</button>
			</div>
		</form>
	);
};

export default CreateListForm;

CreateListForm.propTypes = {
	addNewList: PropTypes.func,
	newList: PropTypes.string,
	updateNewList: PropTypes.func,
	setIsLoading:PropTypes.func,
	translations: PropTypes.object,
	isCreated:PropTypes.bool

};

