import React from "react";
import PropTypes from "prop-types";
import overflowHidden from "../../hooks/overflowHidden";

export default function SearchResultCard({
	searchResult,
	currentLocale,
	gridView,
	translations,
	setListModalOpen,
	listModalOpen,
	setDocSelected

}) {

	return (
		<div className={`search-result ${gridView && "view--grid"}`}>
			<div className="search-result__text">
				<h3>{searchResult._source?.title?.[currentLocale]}</h3>
			</div>
			<div className="search-result__links">
				<a href={window.origin + '/' + currentLocale +'/documents/' + searchResult._id}
					className="button--view">{
						translations.documents.view}
				</a>

				<button
					className="button--list"
					onClick={()=>{
						overflowHidden(listModalOpen)
						setListModalOpen(true)
						setDocSelected(searchResult._id)}}>{translations.list.add_to_list}</button>
			</div>
		</div>
	);
}

SearchResultCard.propTypes = {
	searchResult: PropTypes.instanceOf(Object).isRequired,
	currentLocale: PropTypes.string.isRequired,
	gridView: PropTypes.bool.isRequired
};
