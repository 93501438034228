import React from "react";
import PropTypes from "prop-types";

import TextSizeButtons from "../TextSizeButtons";
import Auth from "../Auth/Auth";
import overflowHidden from "../../hooks/overflowHidden";

export default function MobileMenu({setIsLoggedIn, isLoggedIn, setMenuOpen, locale, user, csrf, translations, menuOpen}) {

	const windowUrl = window.origin;

	return (
		<div className="mobile-menu">
			<div className="main-grid">
				<div className="margin" />

				<div className="mobile-menu__body">
					<header className="mobile-menu__header">
						<div
							className="close-menu"
							tabIndex="0"
							aria-label="close menu"
							onClick={() => {
								overflowHidden(menuOpen)
								setMenuOpen(false)
							}}
						>
							<span className="close-menu--line"></span>
							<span className="close-menu--line"></span>
						</div>
					</header>
					<div className="mobile-menu__content">
						<nav className="mobile-menu__nav">
							<ul>
								{isLoggedIn && (
									<>
										<li className="menu-item">
											<a href={windowUrl + '/' + locale + '/lists'}>
												{translations.myLists}
											</a>
										</li>
										<li className="menu-item">
											<a href="">
												{translations.account}
											</a>
										</li>
									</>
								)}
								<li className="menu-item">
									{locale === "en" ? (
										<a href="/fr">
											{translations.locale}
										</a>
									) : (
										<a href="/en">
											{translations.locale}
										</a>
									)}
								</li>
							</ul>
							<div className="mobile-menu__content__text-buttons">
								<TextSizeButtons/>
							</div>
						</nav>
						<div className="mobile-menu__button">
							<Auth
								locale={locale}
								isLoggedIn={isLoggedIn}
								setIsLoggedIn={setIsLoggedIn}
								user={user}
								setMenuOpen={setMenuOpen}
								csrf={csrf}
								translations={translations}
							/>
						</div>
					</div>
				</div>

				<div className="margin" />
			</div>
		</div>
	);
}

MobileMenu.propTypes = {
	isLoggedIn: PropTypes.bool.isRequired,
	setLoggedIn: PropTypes.bool.isRequired,
	setMenuOpen: PropTypes.func.isRequired,
	locale: PropTypes.string.isRequired,
	setIsLoggedIn: PropTypes.func.isRequired,
	user: PropTypes.instanceOf(Object),
	csrf: PropTypes.string.isRequired,
	translations: PropTypes.instanceOf(Object).isRequired,
	menuOpen: PropTypes.bool.isRequired
};
