import Axios from "axios";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import DocumentCard from "../../documents/DocumentCard";
import RenameListModal from "../rename/RenameListModal";
import overflowHidden from "../../hooks/overflowHidden";
let arrayForHoldingDocs = [];

// this is the view of a single list, it appears on the blade template user_list.show

const ListView = ({ currentLocale, translationsString }) => {
	const windowUrl = window.origin;
	const listUrl = window.location.href + "?ajax=1";
	const translations = JSON.parse(translationsString);
	const docsPerPage = 12;

	// states
	const [locale, setLocale] = useState("");
	const [documents, setDocuments] = useState(null);
	const [error, setError] = useState(false);
	const [listModalOpen, setListModalOpen] = useState(false);
	const [listTitle, setListTitle] = useState(null);
	const [isDeleted, setIsDeleted] = useState(false);
	const [renameListModalOpen, setRenameListModalOpen] = useState(false);
	const [docsToShow, setDocsToShow] = useState([]);
	const [next, setNext] = useState(12);



	// AXIOS CALLS

	/// get documents from API for this List
	const getData = async () => {
		const response = await Axios.get(listUrl);

		if (response.data.success) {
			// set the documents we are viewing

			setDocuments(response.data.data.documents);
			setError(false);
			setListTitle(response.data.data.title);

		}
	};

	// Delete the entire list
	const deleteList = async ()=>{
		const redirect =()=>{
			return window.location.href = window.location.origin + '/'+ locale
		}
		try {
			const response = await Axios.delete(listUrl);
			if (response.data.success) {
				//  confirm list deletion & redirect to the home page
				setIsDeleted(true)
				setTimeout( redirect, 750)
			}
		} catch (err) {
			setError(err.response.data.message);
		}
	}

	// Remove a specific Document from this List

	const removeDocFromList = async (docId)=>{
		// get an array of doc id's of all the documents EXCEPT the one we wish to remove
		const newDocArr = documents.filter(doc => doc.id !== docId).map(doc => doc.id)

		try {
			const response = await Axios.patch(listUrl,

				{documents: newDocArr,
					mode: false
				}
			)

			if (response.data.success){

				setDocuments(response.data.data.documents)
				// reset page load to first set so count is correct
				arrayForHoldingDocs = [];
				setNext(12)
				setError(false)

			}

		}catch(err){
			setError(err.response.status + ": " + err.response.statusText);

		}
	}

	/// LOAD MORE FUNCTIONS

	// get the next set of docs, and add to docs to show array
	const addNextDocsToArr = (start, end) => {

		const slicedDocs = documents.slice(start, end);
		arrayForHoldingDocs = [...arrayForHoldingDocs, ...slicedDocs];
		setDocsToShow(arrayForHoldingDocs);

	};

	// each click get the next set of docs, and move the counter (next) up
	const handleShowMoreDocs = () => {
		addNextDocsToArr(next, next + docsPerPage);
		setNext(next + docsPerPage);
	};


	useEffect(() => {
		setLocale(currentLocale);
		if (locale) {
			getData().catch((error) => {
				setError(error.response.data.message);
			});
		}
	}, [locale]);

	/// once we have documents we only load the first set
	useEffect(()=>{
		if(documents){
			addNextDocsToArr(0, docsPerPage);
		}

	}, [documents])


	return (
		<>
			{error && <div className="document-view__error">{error}</div>}

			{!error && documents &&  (
				<>
					<section
						className="main-grid user-lists"
						aria-labelledby="list-title"
					>
						<div className="main-grid__margin"></div>
						{!isDeleted && (
							<div className="main-grid__body">

								<a href={window.origin + "/" + locale + "/lists"} className="user-lists__return">{translations.list.my_lists}</a>
								<h1 className="user-lists__title" id="lis-title">{listTitle}</h1>
								<div className="user-lists__link-wrap">
									<p>
										{documents.length === 1? documents.length + ' document'
											:  documents.length + ' documents'}
									</p>
									<button
										className="button--underline"
										onClick={()=>{
											overflowHidden(renameListModalOpen)
											setRenameListModalOpen(true)
										}}
									>{translations.list.rename}</button>

								</div>


								<div className="cards-grid--documents">
									{docsToShow.map((document) => {
										return (
											<DocumentCard
												key={document.id}
												document={document}
												locale={locale}
												windowUrl={windowUrl}
												translations={translations}
												setListModalOpen={setListModalOpen}
												listModalOpen={listModalOpen}
												isListView={true}
												removeDocFromList={removeDocFromList}
											/>
										);
									})}
								</div>
								{documents.length > docsToShow.length && documents.length > docsPerPage &&(
									<button className="button--load-more" onClick={handleShowMoreDocs}>{translations.list.load_more}</button>

								)}

								<button
									className="button--underline delete-list"
									onClick={deleteList}
								>{translations.list.delete_list}</button>
							</div>

						)}
						{isDeleted &&(
							<div className="main-grid__body">
								<p className="align--center margin__top--xlarge">{translations.list.list_deleted}</p>
							</div>

						)}

						<div className="main-grid__margin"></div>
					</section>
					{renameListModalOpen &&  (
						<RenameListModal
							renameListModalOpen={renameListModalOpen}
							setRenameListModalOpen={setRenameListModalOpen}
							listUrl={listUrl}
							translations={translations}
							setListTitle={setListTitle}
						/>
					)}

				</>
			)}
		</>
	);
};

export default ListView;

ListView.propTypes = {
	currentLocale: PropTypes.string,
	translationsString: PropTypes.string,
};

if (document.getElementById("list-view")) {
	const documentsElement = document.getElementById("list-view");
	ReactDOM.render(
		<ListView {...documentsElement.dataset} />,
		documentsElement
	);
}
