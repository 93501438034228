import React from 'react';

// Loading animation to be used in modals

const ModalLoading = () => {
	return (
		<div className="modal__loading">
			<div className="loading__container">
				<svg className="loading__spinner" viewBox="0 0 100 100">
					<circle
						cx="50"
						cy="50"
						fill="transparent"
						r="25"
						stroke="#f4363d"
						strokeWidth="10"
					/>
				</svg>
			</div>
		</div>
	);
};

export default ModalLoading;
