import React from "react";
import { useState } from "react";
import Axios from "axios";
import PropTypes from "prop-types";

import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import overflowHidden from "../../hooks/overflowHidden";
import ResetPasswordModal from "./ResetPasswordModal";

export default function Auth({locale, isLoggedIn, csrf, user, setMenuOpen, setIsLoggedIn, translations}) {

	const [loginOpen, setLoginOpen] = useState(false);
	const [registerOpen, setRegisterOpen] = useState(false);
	const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
	const [error, setError] = useState(null);
	const windowUrl = window.origin;
	const logoutApiUrl = `${windowUrl}/logout`;

	const logout = async (event) => {
		event.preventDefault();

		try {
			await Axios.post(logoutApiUrl).then(() => {
				setIsLoggedIn(false);
				setMenuOpen && setMenuOpen(false);
				// we have to reload the page to update the state of the blade template, only way to avoid this is to make a full react app
				window.location.reload();
			});
		} catch (error) {
			setError(error);
		}
	}

	if (isLoggedIn && user) {
		return (
			<>
				<p className="user">
					<a href={`/${locale}/profile`}>
						{translations.welcome}
						{user.name}
					</a>
				</p>
				{error && (
					<div className="modal__error">
						<p>{error}</p>
					</div>
				)}
				<p className="menu-item" onClick={logout}>
					{translations.logout}
				</p>
			</>
		);
	}

	return (
		<>
			<span
				className="menu-item"
				onClick={() => {
					overflowHidden(loginOpen)
					setLoginOpen(true)
				}}
			>
				{translations.login}
			</span>
			<span
				className="menu-item"
				onClick={() => {
					overflowHidden(registerOpen)
					setRegisterOpen(true)
				}}
			>
				{translations.register}
			</span>
			{loginOpen && (
				<LoginModal
					csrf={csrf}
					setLoginOpen={setLoginOpen}
					setRegisterOpen={setRegisterOpen}
					setIsLoggedIn={setIsLoggedIn}
					setResetPasswordOpen={setResetPasswordOpen}
					locale={locale}
					loginOpen={loginOpen}
					translations={translations}
				/>
			)}
			{resetPasswordOpen && (
				<ResetPasswordModal
					setResetPasswordOpen={setResetPasswordOpen}
					translations={translations}
					resetPasswordOpen={registerOpen}
					csrf={csrf}
					setLoginOpen={setLoginOpen}
					setRegisterOpen={setRegisterOpen}
					
				/>
			)}

			{registerOpen && (
				<RegisterModal
					locale={locale}
					csrf={csrf}
					loginOpen={loginOpen}
					registerOpen={registerOpen}
					setLoginOpen={setLoginOpen}
					setRegisterOpen={setRegisterOpen}
					setIsLoggedIn={setIsLoggedIn}
					translations={translations}
				/>
			)}
			
		</>
	);
}

Auth.propTypes = {
	locale: PropTypes.string.isRequired,
	isLoggedIn: PropTypes.bool.isRequired,
	csrf: PropTypes.string.isRequired,
	user: PropTypes.instanceOf(Object),
	setMenuOpen: PropTypes.func,
	setIsLoggedIn: PropTypes.func.isRequired,
	translations: PropTypes.instanceOf(Object).isRequired
};
