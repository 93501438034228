import React from "react";
import PropTypes from "prop-types";
import DocumentCard from '../documents/DocumentCard'


const RelatedDocuments = ({related, locale, translations, listModalOpen, setListModalOpen}) => {

	return (
		<section
			className="main-grid documents related"
			aria-labelledby="related-documents-title"
		>
			<div className="main-grid__margin"></div>
			<div className="main-grid__body">

				<h2 id="related-documents-title" className="documents__title">
					{translations.documents.related_documents}
				</h2>
				<div className="cards-grid--documents">
					{related.map( document =>{
						return (

							<DocumentCard
								key={document.id}
								document={document}
								locale={locale}
								windowUrl={window.origin}
								translations={translations}
								setListModalOpen={setListModalOpen}
								listModalOpen={listModalOpen}
							/>

						)
					})}
				</div>


			</div>
			<div className="main-grid__margin"></div>
		</section>
	);
};

RelatedDocuments.propTypes = {
	related: PropTypes.array,
	locale: PropTypes.string,
	translations: PropTypes.object,
	setListModalOpen: PropTypes.func,
	listModalOpen: PropTypes.bool,
}

export default RelatedDocuments;
