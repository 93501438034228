import React from "react";
import ReactDOM from "react-dom";
import { useState } from "react";
import Axios from "axios";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json"
import frLocale from "i18n-iso-countries/langs/fr.json"

export default function ProfilePage({translationsJson, userJson, csrf, locale}) {

	const translations = JSON.parse(translationsJson);
	const user = JSON.parse(userJson);

	const windowUrl = window.origin;
	const profileApiUrl = `${windowUrl}/${locale}/profile`;

	countries.registerLocale(enLocale);
	countries.registerLocale(frLocale);
	const countriesObj = countries.getNames( locale, {select: "official"});
	const countriesArr = Object.entries(countriesObj).map(([key, value]) => {
		return {
			label: value,
			value: key
		}
	})

	// controlled input for each form field
	const [name, setName] = useState(user.name);
	const [email, setEmail] = useState(user.email);
	const [countryResidence, setCountryResidence] = useState(user.iso_country ?? "");
	const [countryInterest, setCountryInterest] = useState(user.iso_research_country ?? "");
	const [type, setType] = useState(user.researcher_type);
	const [institution, setInstitution] = useState(user.affiliated_org);
	const [language, setLanguage] = useState(user.language_preference);
	const [password, setPassword] = useState("");
	const [passwordConfirmation, setPasswordConfirmation] = useState("");
	const [subscribed, setSubscribed] = useState(user.subscribed_newsletter);
	const [countriesList, setCountriesList] = useState([]);

	// async call states
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(null);


	// update data on submit
	const handleFormSubmit = () => {

		setLoading(true);
		setError(null);

		const formBody = {
			name: name,
			email: email,
			iso_country: countryResidence,
			iso_research_country: countryInterest,
			researcher_type: type,
			affiliated_org: institution,
			language_preference: language,
			password: password,
			password_confirmation: passwordConfirmation,
			_token: csrf,
		};

		// https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
		// For patch requests, forms need to be of type "url encoded" -- this might be a think with Laravel?
		const formData = new URLSearchParams();
		const options = {
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
		}

		for (const field in formBody) {
			formData.append(field, formBody[field]);
		}

		return Axios.patch(profileApiUrl, formData, options)
			.then((res) => {
				if (res.data?.success) {
					setLoading(false);
					setSuccess(res.data.success)
				}
			})
			.catch((error) => {
				if (error.response) {
					setError(error.response);
				}
			});

	}

	return (
		<div className="profile__body">
			<div className="profile__header">
				<h1 className="card-header">{translations.welcome + user.name}</h1>
				<p>{translations.completeProfile}</p>
			</div>
			{error ? (
				<div className="error">
					<h3>{error}</h3>
				</div>
			) : (
				<form className="profile__form" onSubmit={(event) => {
					event.preventDefault();
					handleFormSubmit();
				}}>
					<div className="profile__form--input">
						<input type="text" id="profile__name" required value={name} onChange={(e) => setName(e.target.value)} />
						<label htmlFor="profile__name">{translations.fullName}</label>
					</div>
					<div className="profile__form--input">
						<input type="text" id="profile__email" required value={email} onChange={(e) => setEmail(e.target.value)} />
						<label htmlFor="profile__email">{translations.email}</label>
					</div>
					<div className="profile__form--select">
						<select name="country-residence" id="profile__country-residence" required value={countryResidence} onChange={(e) => setCountryResidence(e.target.value)} name="country">
							<option value="">{translations.countryResidence}</option>
							{countriesArr.map((country) => {
								return (
									<option value={country.value}>{country.label}</option>
								)
							})}
						</select>
						<label htmlFor="profile__country-residence"></label>
					</div>
					<div className="profile__form--select">
						<select name="country-interest" id="profile__country-interest" required value={countryInterest} onChange={(e) => setCountryInterest(e.target.value)} name="country">
							<option value="">{translations.countryInterest}</option>
							{countriesArr.map((country) => {
								return (
									<option value={country.value}>{country.label}</option>
								)
							})}
						</select>
						<label htmlFor="profile__country-residence"></label>
					</div>
					<div className="profile__form--input">
						<input type="text" id="profile__type" required value={type} onChange={(e) => setType(e.target.value)} />
						<label htmlFor="profile__type">{translations.type}</label>
					</div>
					<div className="profile__form--input">
						<input type="text" id="profile__institution" required value={institution} onChange={(e) => setInstitution(e.target.value)} />
						<label htmlFor="profile__institution">{translations.institution}</label>
					</div>
					<div className="profile__form--select">
						<select name="language" id="profile__language" value={language} onChange={(e) => setLanguage(e.target.value)} >
							<option value="">{translations.language}</option>
							<option value="en">English</option>
							<option value="fr">Français</option>
						</select>
						<label htmlFor="profile__type"></label>
					</div>
					<div className="profile__form--input">
						<input type="password" id="profile__password" required value={password} onChange={(e) => setPassword(e.target.value)} />
						<label htmlFor="profile__password">{translations.password}</label>
					</div>
					<div className="profile__form--input">
						<input type="password" id="profile__password-confirmation" required value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} />
						<label htmlFor="profile__password-confirmation">{translations.passwordConfirmation}</label>
					</div>
					<div className="profile__form--checkbox">
						<input type="checkbox" name="email-updates" id="updates" checked={subscribed} onChange={(e) => setSubscribed(!subscribed)}/>
						<label htmlFor="updates">
							{translations.updates}
							<a href="/privacy-policy">{translations.privacyPolicy}</a>
						</label>
					</div>
					<div className="profile__form--button">
						<input className="button" type="submit" name="email-button" value={translations.continue}/>
					</div>
					{success && (
						<div className="success">
							<p>{translations.success}</p>
						</div>
					)}
					{loading && (
						<div className="loading">
							<div className="loading__container">
								<svg className="loading__spinner" viewBox="0 0 100 100">
									<circle
										cx="50"
										cy="50"
										fill="transparent"
										r="25"
										stroke="#f4363d"
										strokeWidth="10"
									/>
								</svg>
							</div>
						</div>
					)}
				</form>
			)}
		</div>
	)
}

if (document.getElementById("profilePage")) {
	const profileTag = document.getElementById('profilePage')
	ReactDOM.render(<ProfilePage {...profileTag.dataset} />, document.getElementById("profilePage"));
}
