import Axios from "axios"

const getSearchData = (setSearchResults, setTotalResults, setLoading, setError, setCurrentPage, setPagesNumber, searchUrl) => {

	const searchParams = new URLSearchParams(searchUrl)
	const pageString = searchParams.get("page");
	const page = parseInt(pageString);

	Axios.get(searchUrl)
		.then((res) => {
			if (res.data?.results?.length) {
				setCurrentPage(parseInt(page))
				setSearchResults(res.data.results);
				setTotalResults(res.data.total);
				setLoading(false);
				setError(null);
			} else {
				setSearchResults([]);
				setTotalResults(0);
				setPagesNumber(1);
				// if no search results set "no results" error message
				setError(
					currentLocale === "en" ? "No results" : "Pas de résultats"
				);
			}
			setLoading(false);
		})
		.catch((err) => {
			if (err.response) {
				setError(err.response.message);
				setSearchResults([]);
				setLoading(false);
			}
		});
}

export default getSearchData;
