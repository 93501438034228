import PropTypes from "prop-types";
import React, { useState } from "react";
import overflowHidden from "../../hooks/overflowHidden";
import Axios from "axios";
import ModalLoading from "../../ModalLoading";
import RenameListForm from "../rename/RenameListForm";

// Create a list Modal is launched from the MyListModal only

const RenameListModal = ({
	renameListModalOpen,
	setRenameListModalOpen,
	translations,
	listUrl,
	setListTitle
}) => {


	//state
	const [error, setError] = useState(false);
	const [newListName, setNewListName] = useState("");
	const [isRenamed, setIsRenamed] = useState(false);
	const [isLoading, setIsLoading] = useState(null);


	// closeModal in a function so we can call it set timeout

	const closeModal = () => {
		overflowHidden(renameListModalOpen);
		setRenameListModalOpen(false);
	};

	// clicking outside the modal closes it when it's open, this prevents clicks from inside
	// the modal from bubbling up and closing the modal
	const stopPropagation = (event) => {
		event.stopPropagation();
	};

	/// AXIOS REQUESTS

	// rename the list
	const renameList = async (listName)=>{
		try {
			const response = await Axios.patch(listUrl, { title: listName })
			if(response.data.success){
				setIsRenamed(true)
				setNewListName("");
				setError(false);
				setIsLoading(false)
				setListTitle(listName)
				setTimeout(closeModal, 500)
			}
		}catch (err) {
			setError(err.response.data.message);
		}
	}



	return (
		<div className="list-modal">
			<div className="list-modal__overlay" onClick={closeModal}>
				<div
					className="list-modal__modal modal"
					onClick={stopPropagation}
				>
					<div className="modal__wrap">
						<div className="modal__close" onClick={closeModal}>
							<span className="modal__close--line" />
							<span className="modal__close--line" />
						</div>
						<div className="modal__text">
							<h2>{translations.list.rename_list}</h2>

						</div>
						{isLoading && (
							<>
								<ModalLoading />
							</>
						)}

						{error && (
							<div className="modal__error">
								<p>{error}</p>
							</div>
						)}

						{!isLoading && !error &&  (
							<>
								<RenameListForm
									setIsLoading={setIsLoading}
									setNewListName={setNewListName}
									newListName={newListName}
									translations={translations}
									renameList={renameList}
									isRenamed={isRenamed}

								/>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default RenameListModal;

RenameListModal.propTypes = {
	locale: PropTypes.string,
	createListModalOpen: PropTypes.bool,
	setCreateListModalOpen: PropTypes.func,
	translations: PropTypes.object,
	renameList: PropTypes.func,
	renameListModalOpen: PropTypes.bool,
	setRenameListModalOpen: PropTypes.func,
	listUrl: PropTypes.string,
	setListTitle: PropTypes.func,

}

