import React from 'react';
import PropTypes from "prop-types";
import overflowHidden from '../hooks/overflowHidden';

// The card that is seen on the Documents Index & Related document section on the single document view

const  DocumentCard = ({
	document,
	windowUrl,
	locale,
	translations,
	setListModalOpen,
	listModalOpen,
	setDocSelected,
	isListView,
	removeDocFromList}) =>{

	// remove this doc from list


	const date = new Date(document.publication_date).toLocaleDateString(
		`${locale + '-ca'}`,
		{
			year: 'numeric',
			month: 'long',
			day: 'numeric',
			timeZone: 'utc'
		}
	);



	return (
		<div className="card-document">
			<div className="card-document__content">
				<h3>{document.title[locale]?document.title[locale]:document.title[document.language]}</h3>
				{document.country && (
					<span>{document.country}{', '}</span>
				)}
				<span>{date}</span>
			</div>
			<div className="button-wrap">
				<a href={windowUrl+ '/' + locale +'/documents/' + document.id}
					className="button--view">{
						translations.documents.view}
				</a>
				{isListView?
					<button className="button--underline remove"
						onClick={()=>removeDocFromList(document.id)}
					>{translations.list.remove}
					</button>
					:<button className="button--list"
						onClick={()=>{
							overflowHidden(listModalOpen)
							setListModalOpen(true)
							setDocSelected(document.id)
						}
						}>{translations.documents.add_to_list}</button>


				}
			</div>
		</div>
	);
}

export default DocumentCard;

DocumentCard.propTypes = {
	locale: PropTypes.string,
	windowUrl: PropTypes.string,
	document: PropTypes.object,
	translations: PropTypes.object,
	isListView: PropTypes.bool,
	setListModalOpen: PropTypes.func,
	listModalOpen: PropTypes.bool,
	setDocSelected: PropTypes.func,
	removeDocFromList: PropTypes.func
}

DocumentCard.defaultProps ={
	isListView: false
}

