import React from "react";
import Axios from "axios";
import { useState } from "react";
import PropTypes from "prop-types";

import useInput from "../../hooks/useInput";
import overflowHidden from "../../hooks/overflowHidden";
import ModalLoading from "../../ModalLoading";

export default function RegisterModal({setLoginOpen, setRegisterOpen, csrf, setIsLoggedIn, translations, registerOpen}) {

	const name = useInput("");
	const email = useInput("");
	const password = useInput("");
	const passwordConfirmation = useInput("");
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const windowUrl = window.origin;
	const loginApiUrl = `${windowUrl}/register`;

	// clicking outside the modal closes it when it's open, this prevents clicks from inside the modal from bubbling up and closing the modal
	const stopPropagation = (event) => {
		event.stopPropagation();
	}

	const handleFormSubmit = (event) => {
		event.preventDefault();

		setLoading(true);
		setError(null)

		const formBody = {
			name: name.value,
			email: email.value,
			password: password.value,
			// ! camel case rule giving me error for line below but I don't think we should turn it off
			password_confirmation: passwordConfirmation.value, //eslint-disable-line
			_token: csrf
		}

		// https://developer.mozilla.org/en-US/docs/Web/API/FormData
		const formData = new FormData();

		for (const field in formBody) {
			formData.append(field, formBody[field]);
		}

		return Axios.post(loginApiUrl, formData).then(() => {
			setIsLoggedIn(true);
			setLoading(false);
			setRegisterOpen(false);
			// we have to reload the page to update the state of the blade template, only way to avoid this is to make a full react app
			window.location.reload();
		}).catch((error) => {
			if (error.response) {
				setLoading(false);
				if (error.response.data.errors.email || error.response.data.errors.password) {
					setError(error.response.data.errors.email || error.response.data.errors.password);
				}
			}
		})
	}

	return (
		<div className="login">
			<div
				className="login__overlay"
				onClick={() => {
					overflowHidden(registerOpen)
					setRegisterOpen(false)
				}}
			>
				<div className="login__modal modal" onClick={stopPropagation}>
					<div className="modal__wrap">
						<div
							className="modal__close"
							onClick={() => {
								overflowHidden(registerOpen)
								setRegisterOpen(false)
							}}
						>
							<span className="modal__close--line" />
							<span className="modal__close--line" />
						</div>
						<div className="modal__text">
							<h2>{translations.createAccount}</h2>
							<p>
								{translations.alreadyAccount}
								<span className="link" onClick={() => {
									setRegisterOpen(false)
									setLoginOpen(true)
								}}>
									{translations.signIn}
								</span>
							</p>
						</div>
						{loading ? (
							<ModalLoading/>
						) : (
							<form action="" className="modal__form" onSubmit={handleFormSubmit}>
								<div className="modal__form--input">
									<input type="text" id="login__email" required {...name} />
									<label htmlFor="login__email">{translations.fullName}</label>
								</div>
								<div className="modal__form--input">
									<input type="text" id="login__email" required {...email} />
									<label htmlFor="login__email">{translations.email}</label>
								</div>
								<div className="modal__form--input">
									<input type="password" id="login__password" required {...password} />
									<label htmlFor="login__password">{translations.password}</label>
								</div>
								<div className="modal__form--input">
									<input type="password" id="login__password-confirmation" required {...passwordConfirmation} />
									<label htmlFor="login__password">{translations.confirmPassword}</label>
								</div>
								{error && (
									<div className="modal__error">
										<p>{error}</p>
									</div>
								)}
								<div className="modal__form--submit">
									<button type="submit">{translations.joinNow}</button>
								</div>
							</form>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

RegisterModal.propTypes = {
	setLoginOpen: PropTypes.func.isRequired,
	setRegisterOpen: PropTypes.func.isRequired,
	csrf: PropTypes.string.isRequired,
	setIsLoggedIn: PropTypes.func.isRequired,
	translations: PropTypes.instanceOf(Object).isRequired,
	registerOpen: PropTypes.bool.isRequired
};
