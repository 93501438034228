import Axios from "axios";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { PropTypes, oneOfType } from "prop-types";
import DocumentCard from "./DocumentCard";
import Hero from "../hero/Hero";
import AddToListModal from "../lists/add/AddToListModal";
import Pagination from "./DocumentPagination";
import qs from "qs";

const Documents = ({
    currentLocale,
    translationsString,
    arrowIcon,
    userString,
}) => {
    
    const windowUrl = window.origin;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());
    const translations = JSON.parse(translationsString);

    // states
    const [locale, setLocale] = useState("");
    const [documents, setDocuments] = useState(null);
    const [collection, setCollection] = useState(null);
    const [error, setError] = useState(false);
    const [listModalOpen, setListModalOpen] = useState(false);
    const [docSelected, setDocSelected] = useState(null);

    const [params, setParams] = useState({});
    const [pagesNumber, setPagesNumber] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [allPages, setAllPages] = useState([]);

    const getData = async () => {
        /// get documents & collection from API for this collection
        const documentsApiUrl = `${windowUrl}/${locale}/documents?${qs.stringify(
            params
        )}`;
        const response = await Axios.get(documentsApiUrl);

        if (response.data.success) {
            const responseObj = response.data.data;
            // we need the documents in an array
            const documents = Object.entries(responseObj).filter(
                (document) => document[0] !== "collection"
            );
            // set the collection we are viewing
            setCollection(responseObj["collection"]);
            setDocuments(documents);
            setError(false);

            // determine how many pages to create
            if (response.data.last_page) {
                setPagesNumber(response.data.last_page);
            }
        }
    };

    // first useffect creates query parameters object with their default values if there are any present in the url
    useEffect(() => {
        // turn search params into object
        const parsedUrlParams = qs.parse(urlParams);

        if (parsedUrlParams.page) {
            setCurrentPage(parseInt(parsedUrlParams.page));
        }

        setParams({
            ...urlParams,
            current_page: currentPage,
        });
    }, [setParams]);

    // 2nd useffect runs the query with the parameters object previously created in the first useffect
    useEffect(() => {
        setLocale(currentLocale);

        if (locale) {
            getData().catch((error) => {
                setError(error.response.data.message);
            });
            // wait until we have locale to run get documents
        }
    }, [params]);

    // 3rd useffect sets up pagination once we receive the response from the previous one
    useEffect(() => {
        let allPagesArray = [];

        for (let i = 1; i <= pagesNumber; i++) {
            allPagesArray.push(i);
        }

        setAllPages(allPagesArray);
    }, [pagesNumber]);

    return (
        <>
            {error && <div className="document-view__error">{error}</div>}
            {!error && collection && (
                <Hero collection={collection} locale={locale} />
            )}

            {!userString && !error && (
                <div className="document-view__error">
                    {translations.documents.no_auth}
                </div>
            )}
            {!error && documents && userString && (
                <>
                    <section
                        className="main-grid documents"
                        aria-label="Documents"
                    >
                        <div className="main-grid__margin"></div>
                        <div className="main-grid__body">
                            <h2 className="documents__title">
                                {translations.documents.documents}
                            </h2>
                            <div className="cards-grid--documents">
                                {documents.map((document) => {
                                    return (
                                        <DocumentCard
                                            key={document[1].id}
                                            document={document[1]}
                                            locale={locale}
                                            windowUrl={windowUrl}
                                            translations={translations}
                                            setListModalOpen={setListModalOpen}
                                            listModalOpen={listModalOpen}
                                            setDocSelected={setDocSelected}
                                        />
                                    );
                                })}
                            </div>
                            {pagesNumber > 1 && (
                                <Pagination
                                    allPages={allPages}
                                    currentPage={currentPage}
                                    arrowIcon={arrowIcon}
                                    currentLocale={currentLocale}
                                    pageName={"documents"}
                                    filters={urlParams}
                                />
                            )}
                        </div>
                        <div className="main-grid__margin"></div>
                    </section>
                    {listModalOpen && docSelected && (
                        <AddToListModal
                            listModalOpen={listModalOpen}
                            setListModalOpen={setListModalOpen}
                            locale={locale}
                            docSelected={docSelected}
                            translations={translations}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Documents;

Documents.propTypes = {
    currentLocale: PropTypes.string,
    translationsString: PropTypes.string,
	userString:oneOfType([
		PropTypes.string,
		PropTypes.object
	  ]),
};

if (document.getElementById("documents")) {
    const documentsElement = document.getElementById("documents");
    ReactDOM.render(
        <Documents {...documentsElement.dataset} />,
        documentsElement
    );
}
