import React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import SearchResultCard from "./SearchResultCard";
import MobileFilters from "./MobileFilters";
import overflowHidden from "../../hooks/overflowHidden";
import Pagination from "./Pagination";
import ModalLoading from "../../ModalLoading";

export default function SearchResultsList({
    translations,
    isDesktop,
    searchResults,
    currentLocale,
    error,
    loading,
    totalResults,
    currentPage,
    pagesNumber,
    arrowIcon,
    setFilters,
    collection,
    type,
    country,
    language,
    setCollection,
    setType,
    setCountry,
    setLanguage,
    setStartDate,
    setEndDate,
    setCollectionLabel,
    setTypeLabel,
    setCountryLabel,
    setLanguageLabel,
    setStartDateLabel,
    setEndDateLabel,
    startDate,
    endDate,
    filters,
    setDocSelected,
    setListModalOpen,
    listModalOpen,
    setSearchResults,
    setTotalResults,
    setLoading,
    setError,
    setCurrentPage,
    setPagesNumber,
    searchUrl,
    searchParams,
}) {
	const [gridView, setGridView] = useState(false);
	const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
	const [allPages, setAllPages] = useState([]);

	// update pages number on state change
	useEffect(() => {
		let allPagesArray = [];

		for (let i = 1; i <= pagesNumber; i++) {
			allPagesArray.push(i);
		}

		setAllPages(allPagesArray);
	}, [pagesNumber, setAllPages]);

    const displayTotalResults = () => {
        if (totalResults === null) return null;

        if (totalResults === 0) {
            return (
                <p>
                    {totalResults} {translations.search.results}
                </p>
            );
        }
     
        return (
            <p>
                {totalResults.value} {translations.search.results}
            </p>
        );
    };

    return (
        <div className="results">
            <div className="results__nav">
                <div>{displayTotalResults()}</div>

                {isDesktop ? (
                    <span
                        className="link"
                        onClick={() => setGridView(!gridView)}
                    >
                        {gridView
                            ? translations.search.listView
                            : translations.search.gridView}
                    </span>
                ) : (
                    <span
                        className="link"
                        onClick={() => {
                            overflowHidden(mobileFiltersOpen);
                            setMobileFiltersOpen(true);
                        }}
                    >
                        {translations.search.advancedSearch}
                        {mobileFiltersOpen && (
                            <MobileFilters
                                translations={translations}
                                mobileFiltersOpen={mobileFiltersOpen}
                                setMobileFiltersOpen={setMobileFiltersOpen}
                                currentLocale={currentLocale}
                                setFilters={setFilters}
                                collection={collection}
                                type={type}
                                country={country}
                                language={language}
                                setCollection={setCollection}
                                setType={setType}
                                setCountry={setCountry}
                                setLanguage={setLanguage}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                setCollectionLabel={setCollectionLabel}
                                setTypeLabel={setTypeLabel}
                                setCountryLabel={setCountryLabel}
                                setLanguageLabel={setLanguageLabel}
                                setStartDateLabel={setStartDateLabel}
                                setEndDateLabel={setEndDateLabel}
                                startDate={startDate}
                                endDate={endDate}
                            />
                        )}
                    </span>
                )}
            </div>
            <div className={`results__list ${gridView && "view--grid"}`}>
                {loading && !totalResults && totalResults !== 0 && (
                    <ModalLoading />
                )}

                {searchResults.map((searchResult) => {
                    return (
                        <SearchResultCard
                            key={searchResult._id}
                            translations={translations}
                            currentLocale={currentLocale}
                            gridView={gridView}
                            searchResult={searchResult}
                            setListModalOpen={setListModalOpen}
                            listModalOpen={listModalOpen}
                            setDocSelected={setDocSelected}
                        />
                    );
                })}
            </div>
            {allPages.length > 1 && (
                <Pagination
                    allPages={allPages}
                    currentPage={currentPage}
                    arrowIcon={arrowIcon}
                    filters={filters}
                    currentLocale={currentLocale}
                    pageName={"search"}
                    setSearchResults={setSearchResults}
                    setTotalResults={setTotalResults}
                    setLoading={setLoading}
                    setError={setError}
                    setCurrentPage={setCurrentPage}
                    setPagesNumber={setPagesNumber}
                    searchUrl={searchUrl}
                    searchParams={searchParams}
                />
            )}
        </div>
    );
}

SearchResultsList.propTypes = {
    translations: PropTypes.instanceOf(Object).isRequired,
    isDesktop: PropTypes.bool.isRequired,
    searchResults: PropTypes.instanceOf(Array),
    currentLocale: PropTypes.string.isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool,
    totalResults: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
    currentPage: PropTypes.number,
    pagesNumber: PropTypes.number,
    arrowIcon: PropTypes.string.isRequired,
    setFilters: PropTypes.func.isRequired,
    setCollection: PropTypes.func.isRequired,
    setType: PropTypes.func.isRequired,
    setCountry: PropTypes.func.isRequired,
    setLanguage: PropTypes.func.isRequired,
    setStartDate: PropTypes.func.isRequired,
    setEndDate: PropTypes.func.isRequired,
    setCollectionLabel: PropTypes.func.isRequired,
    setTypeLabel: PropTypes.func.isRequired,
    setCountryLabel: PropTypes.func.isRequired,
    setLanguageLabel: PropTypes.func.isRequired,
    setStartDateLabel: PropTypes.func.isRequired,
    setEndDateLabel: PropTypes.func.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    filters: PropTypes.instanceOf(Object).isRequired,
};
