import React from "react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Axios from "axios";

import FilterDropdown from "./FilterDropdown";
import DateFilter from "./DateFilter";
import ModalLoading from "../../ModalLoading";

export default function Filters({
	translations,
	currentLocale,
	setCollection,
	setType,
	setCountry,
	setLanguage,
	setStartDate,
	setEndDate,
	collection,
	type,
	country,
	language,
	setCollectionLabel,
	setTypeLabel,
	setCountryLabel,
	setLanguageLabel,
	setStartDateLabel,
	setEndDateLabel,
	startDate,
	endDate
}) {
	const windowUrl = window.origin;
	const collectionsUrl = `${windowUrl}/${currentLocale}/collections`;
	const typesUrl = `${windowUrl}/${currentLocale}/document/types`;
	const countriesUrl = `${windowUrl}/${currentLocale}/countries`;

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	// list of filters data
	const [collections, setCollections] = useState([]);
	const [types, setTypes] = useState([]);
	const [countries, setCountries] = useState([]);

	// languages filter options
	const languages = [
		{
			title: currentLocale === "en" ? "English" : "Anglais",
			value: "en",
		},
		{
			title: currentLocale === "en" ? "French" : "Français",
			value: "fr",
		},
		{
			title: currentLocale === "en" ? "Spanish" : "Espagnol",
			value: "es",
		},
	];

	useEffect(() => {
		setLoading(true);

		const collectionsReq = Axios.get(collectionsUrl);
		const typesReq = Axios.get(typesUrl);
		const countriesReq = Axios.get(countriesUrl);

		Promise.all([collectionsReq, typesReq, countriesReq])
			.then((all) => {
				setCollections(all[0].data.data);
				setTypes(all[1].data.data);
				// break big countries object into array of individual country objects
				setCountries(
					Object.entries(all[2].data.data).map(([key, value]) => ({
						key,
						value,
					}))
				);
				setLoading(false);
			})
			.catch((error) => {
				setError(error.response.message);
			});
	}, []);

	return (
        <div className="filters">
            <div className="filters__wrap">
                <h3 className="filters__title">
                    {translations.search.advancedSearch}
                </h3>
                {loading || error ? (
                    <>
                        {loading && <ModalLoading />}
                        {error && <p>{error}</p>}
                    </>
                ) : (
                    <>
                        <FilterDropdown
                            title={translations.search.collections}
                            data={collections}
                            currentLocale={currentLocale}
                            setFilter={setCollection}
                            filter={collection}
                            setFilterLabel={setCollectionLabel}
                        />
                        <FilterDropdown
                            title={translations.search.type}
                            data={types}
                            currentLocale={currentLocale}
                            setFilter={setType}
                            filter={type}
                            setFilterLabel={setTypeLabel}
                        />
                        <FilterDropdown
                            title={translations.search.country}
                            data={countries}
                            currentLocale={currentLocale}
                            setFilter={setCountry}
                            filter={country}
                            setFilterLabel={setCountryLabel}
                        />
                        <FilterDropdown
                            title={translations.search.language}
                            data={languages}
                            currentLocale={currentLocale}
                            setFilter={setLanguage}
                            filter={language}
                            setFilterLabel={setLanguageLabel}
                        />
                        <DateFilter
                            title={translations.search.date}
                            translations={translations}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            currentLocale={currentLocale}
                            setStartDateLabel={setStartDateLabel}
                            setEndDateLabel={setEndDateLabel}
                            startDate={startDate}
                            endDate={endDate}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

Filters.propTypes = {
	translations: PropTypes.instanceOf(Object).isRequired,
	currentLocale: PropTypes.string.isRequired,
	setCollection: PropTypes.func.isRequired,
	setType: PropTypes.func.isRequired,
	setCountry: PropTypes.func.isRequired,
	setLanguage: PropTypes.func.isRequired,
	setStartDate: PropTypes.func.isRequired,
	setEndDate: PropTypes.func.isRequired,
	setCollectionLabel: PropTypes.func.isRequired,
	setTypeLabel: PropTypes.func.isRequired,
	setCountryLabel: PropTypes.func.isRequired,
	setLanguageLabel: PropTypes.func.isRequired,
	setStartDateLabel: PropTypes.func.isRequired,
	setEndDateLabel: PropTypes.func.isRequired,
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired,
};
