import React from "react";
import Axios from "axios";
import { useState } from "react";
import PropTypes from "prop-types";

import useInput from "../../hooks/useInput";
import overflowHidden from "../../hooks/overflowHidden";
import ModalLoading from "../../ModalLoading";

export default function LoginModal({
	setLoginOpen,
	setRegisterOpen,
	setResetPasswordOpen,
	csrf,
	setIsLoggedIn,
	translations,
	loginOpen,
}) {
	const email = useInput("");
	const password = useInput("");
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	const windowUrl = window.origin;
	const loginApiUrl = `${windowUrl}/login`;

	// clicking outside the modal closes it when it's open, this prevents clicks from inside the modal from bubbling up and closing the modal
	const stopPropagation = (event) => {
		event.stopPropagation();
	};

	const handleFormSubmit = () => {
		setLoading(true);
		setError(null);

		const formBody = {
			email: email.value,
			password: password.value,
			_token: csrf,
		};

		// https://developer.mozilla.org/en-US/docs/Web/API/FormData
		const formData = new FormData();

		for (const field in formBody) {
			formData.append(field, formBody[field]);
		}

		return Axios.post(loginApiUrl, formData)
			.then(() => {
				setIsLoggedIn(true);
				setLoading(false);
				setSuccess(true);

				// we have to reload the page to update the state of the blade template, only way to avoid this is to make a full react app
				setTimeout(() => window.location.reload(), 1500);
			})
			.catch((error) => {
				if (error.response) {
					setLoading(false);
					if (
						error.response.data.errors.email ||
                        error.response.data.errors.password
					) {
						setError(
							error.response.data.errors.email ||
                                error.response.data.errors.password
						);
					}
				}
			});
	};

	const renderModalText =()=>{
		if(!success){
			return (
				<>
				<h2>{translations.signIn}</h2>
				<p>
				{translations.noAccount}
					<span
						className="link"
						onClick={() => {
							setLoginOpen(false);
							setRegisterOpen(true);
						}}
					>
						{translations.join}
					</span>
				</p>
				</>


			)
		} else{
			return (
				<>
					<h2>{translations.success}</h2>
					<p>{translations.successFull}</p>
				</>
			)
		}
	}

	return (
		<div className="login">
			<div
				className="login__overlay"
				onClick={() => {
					overflowHidden(loginOpen);
					setLoginOpen(false);
				}}
			>
				<div className="login__modal modal" onClick={stopPropagation}>
					<div className="modal__wrap">
						<div
							className="modal__close"
							onClick={() => {
								overflowHidden(loginOpen);
								setLoginOpen(false);
							}}
						>
							<span className="modal__close--line" />
							<span className="modal__close--line" />
						</div>
						<div className="modal__text">
							{renderModalText()}								
						</div>
						{loading && <ModalLoading />}
						{!success && !loading && (
							<form
								className="modal__form"
								onSubmit={(event) => {
									event.preventDefault();
									handleFormSubmit();
								}}
							>
								<div className="modal__form--input">
									<input
										type="text"
										id="login__email"
										required
										{...email}
									/>
									<label htmlFor="login__email">
										{translations.email}
									</label>
								</div>
								<div className="modal__form--input">
									<input
										type="password"
										id="login__password"
										required
										{...password}
									/>
									<label htmlFor="login__password">
										{translations.password}
									</label>
								</div>
								{error && (
									<div className="modal__error">
										<p>{error}</p>
									</div>
								)}
								<div className="modal__form--submit">
									<button type="submit">
										{translations.signIn}
									</button>
									<span
										className="link"
										onClick={() => {
											setLoginOpen(false);
											setResetPasswordOpen(true);
										}}
									>
										{translations.forgotPassword}
									</span>
								</div>
							</form>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

LoginModal.propTypes = {
	setLoginOpen: PropTypes.func.isRequired,
	setRegisterOpen: PropTypes.func.isRequired,
	csrf: PropTypes.string.isRequired,
	setIsLoggedIn: PropTypes.func.isRequired,
	translations: PropTypes.instanceOf(Object).isRequired,
	loginOpen: PropTypes.bool.isRequired,
};
