import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";

export default function FilterDropdown({
	title,
	data,
	currentLocale,
	setFilter,
	setFilterLabel,
	filter
}) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className={`filter ${isOpen && "open"}`}>
			<div
				className="filter__title"
				tabIndex="0"
				onClick={() => setIsOpen(!isOpen)}
			>
				<p>{title}</p>
				{isOpen ? <span>&minus;</span> : <span>&#43;</span>}
			</div>
			<div className="filter__list">
				{data.map((item) => {
					// account for each different data structure
					if (item.title?.[currentLocale]) {
						return (
							<p
								className={`filter__list__item ${filter === item.id ? "active" : ""}`}
								tabIndex="0"
								key={item.id}
								onClick={() => {
									setFilterLabel(item?.title?.[currentLocale]);
									setFilter(item.id);
								}}
							>
								{item?.title?.[currentLocale]}
							</p>
						);
					} else if (item.title && typeof item.title !== "object") {
						return (
							<p
								className={`filter__list__item ${filter === item.value ? "active" : ""}`}
								tabIndex="0"
								key={item.value}
								onClick={() => {
									setFilterLabel(item?.title)
									setFilter(item.value)
								}}
							>
								{item?.title}
							</p>
						);
					} else if (item.value) {
						return (
							<p
								className={`filter__list__item ${filter === item.key ? "active" : ""}`}
								tabIndex="0"
								key={item.value}
								onClick={() => {
									setFilterLabel(item?.value)
									setFilter(item.key)
								}}
							>
								{item?.value}
							</p>
						);
					} else if (typeof item === 'string'){
						return (
							<p
								className={`filter__list__item ${filter === item ? "active" : ""}`}
								tabIndex="0"
								key={item.value}
								onClick={() => {
									setFilterLabel(item)
									setFilter(item)
								}}
							>
								{item}
							</p>
						);
					}
				})}
			</div>
		</div>
	);
}

FilterDropdown.propTypes = {
	title: PropTypes.string.isRequired,
	data: PropTypes.instanceOf(Array).isRequired,
	currentLocale: PropTypes.string.isRequired,
	setFilter: PropTypes.func.isRequired,
	setFilterLabel: PropTypes.func.isRequired,
};
