import React from 'react';
import { PropTypes, oneOfType } from "prop-types";

// The card that is seen in the collection module

const  CollectionCard = ({ collection, windowUrl, locale, translations, userString }) =>{

	// check for the description and trim to 24 words

	const excerpt = collection.description?.[locale]?collection.description[locale].split(' ').slice(0, 24).join(' ') + '...' : null;

	return (
		<div className="card animate__hover">
			<a href={userString? windowUrl+ '/' + locale +'/documents?collection=' + collection.id: '/'}>
				{collection.media.length == 2 && (
				// if the collection has a second image loaded, show this
					<div className="card__img">
						<img src={collection.media[1].url} alt={collection.media[1].alt} loading="lazy" height="172px" width="265px"/>
					</div>
				) }

				{collection.media.length == 1 && collection.media[0].collection_name === "feature"  && (
				// if the collection has one image in the feature slot, show the generated thumbnail
					<div className="card__img">
						<img src={collection.media[0].thumbnail} alt={collection.media[0].alt} loading="lazy" height="172px" width="265px"/>
					</div>
				) }
				{collection.media.length == 1 && collection.media[0].collection_name === "thumbnail"  && (
				// if the collection has one image in the thumbnail slot, show this
					<div className="card__img">
						<img src={collection.media[0].url} alt={collection.media[0].alt}  loading="lazy" height="172px" width="265px"/>
					</div>
				) }
				<div className="card__content">
					<h3>
						{collection.title[locale]? collection.title[locale]: collection.title[collection.lang]
							// need default lang on collection for this to work. current it displays nothing if there isn't a translation
						}
					</h3>
					{excerpt && (

						<div className="card__content__desc">{excerpt}</div>

					)}
					<div className="card__content__cta-wrap">
						<span className="cta-link"> {translations.documents.view_collection}</span>
					</div>
				</div>
			</a>
		</div>
	);
}

export default CollectionCard;

CollectionCard.propTypes = {
	locale: PropTypes.string,
	windowUrl: PropTypes.string,
	collection: PropTypes.object,
	translations: PropTypes.object,
	userString:oneOfType([
		PropTypes.string,
		PropTypes.object
	  ]),
}

