import React from "react";

export default function TextSizeButtons() {


	const setFontSize = (fontSize) => {
		// apply `.font--default` or `.font--large` class to body tag to control the font size sitewide
		document.body.className = `font--${fontSize}`;
	}

	return (
		<>
			<button className="text-size text-size--small" onClick={() => setFontSize("default")}>A</button>
			<button className="text-size text-size--large" onClick={() => setFontSize("large")}>A</button>
		</>
	);
}

