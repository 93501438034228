import PropTypes from "prop-types";
import React, { useState , useEffect} from 'react';

// The card that is seen on the Documents Index

const  Hero = ({collection, locale}) =>{

	//state
	const [heroImage, setHeroImage ] = useState(null);

	const determineImage = ()=>{
		const featuredImage = collection.media.filter(obj =>  obj['collection_name'] === "feature")
		if(featuredImage.length > 0){
			setHeroImage(...featuredImage)
		}
	}
	useEffect(() => {
		determineImage()
	}, []);

	return (
		<>
			{heroImage && (
				<section className="hero logged-in">
					<div className="hero__img">
						<img
							src={heroImage.url}
							alt={heroImage.alt}
							loading="lazy"
						/>

					</div>
					<div className="hero__content">
						<h1 className="hero__title">{collection.title[locale]}</h1>
						{collection.description[locale] && (
							<div className="hero__description main-grid">
								<div className="main-grid__margin"></div>
								<p>{collection.description[locale]}</p>
								<div className="main-grid__margin"></div>
							</div>
						)}
					</div>
				</section>
			)}
			{!heroImage && (
				<div className="main-grid padding__top--med-large">
					<div className="main-grid__margin"></div>
					<div className="content-body align--center">
						<h1>{collection.title[locale]}</h1>
						{collection.description && (
							<p className="margin__top--small">{collection.description[locale]}</p>
						)}
					</div>
					<div className="main-grid__margin"></div>
				</div>

			)}
		</>
	);
}

export default Hero;

Hero.propTypes = {
	locale: PropTypes.string,
	collection: PropTypes.object
}

