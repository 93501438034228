import React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import qs from "qs";
import { useMediaQuery } from "react-responsive";

import truncatePages from "../../hooks/truncatePages";
import getSearchData from "../../hooks/getSearchData";

export default function Pagination({
	allPages,
	currentPage,
	arrowIcon,
	filters,
	currentLocale,
	pageName,
	setSearchResults,
	setTotalResults,
	setLoading,
	setError,
	setCurrentPage,
	setPagesNumber,
	totalResults,
	searchParams,
}) {
	const [truncatedPages, setTruncatedPages] = useState([]);
	const isDesktop = useMediaQuery({ query: "(min-width: 1220px)" });

	const prevUrl = new URL(
		`${window.origin}/${pageName}/type?${qs.stringify(filters)}`
	);
	prevUrl.searchParams.set("page", currentPage - 1);

	const nextUrl = new URL(
		`${window.origin}/${pageName}/type?${qs.stringify(filters)}`
	);
	nextUrl.searchParams.set("page", currentPage + 1);

	const handlePageChange = (url) => {
		getSearchData(
			setSearchResults,
			setTotalResults,
			setLoading,
			setError,
			setCurrentPage,
			setPagesNumber,
			url.href,
			totalResults
		);
	};

	useEffect(() => {
		const lastPage = allPages.slice(-1).pop();
		// number of pages to show around active page
		const delta = isDesktop ? 2 : 1;
		setTruncatedPages(truncatePages(currentPage, lastPage, delta));
	}, [allPages, currentPage, isDesktop]);

	// const truncatedPages = truncatePages(currentPage, lastPage);

	return (
		<div className="pagination">
			<div className="pagination__wrap">
				{/* hide prev button if on page 1 */}
				{allPages.length > 1 && currentPage > 1 && (
					<div className="pagination__arrow prev">
						<div onClick={() => handlePageChange(prevUrl)}>
							<img src={arrowIcon} alt="next page icon" />
						</div>
					</div>
				)}
				{truncatedPages.map((page) => {
					if (typeof page === "number") {
						const pageUrl = new URL(
							`${window.origin}/${pageName}/type?${qs.stringify(
								filters
							)}`
						);
						pageUrl.searchParams.set("page", page);
						return (
							<div
								key={page}
								onClick={() => handlePageChange(pageUrl)}
							>
								<h3
									className={`pagination__page ${
										page === currentPage ? "current" : ""
									}`}
								>
									{page}
								</h3>
							</div>
						);
					} else {
						return (
							<div key={page}>
								<h3 className="pagination__page">{page}</h3>
							</div>
						);
					}
				})}
				{/* hide next button if on last page */}
				{allPages.length > 1 && currentPage < allPages.length && (
					<div className="pagination__arrow next">
						<div onClick={() => handlePageChange(nextUrl)}>
							<img src={arrowIcon} alt="next page icon" />
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

Pagination.propTypes = {
	allPages: PropTypes.instanceOf(Object),
	currentPage: PropTypes.number,
	arrowIcon: PropTypes.string,
	filters: PropTypes.instanceOf(Object),
	currentLocale: PropTypes.string,
};
