import React from "react";
import PropTypes from "prop-types";

import MobileMenu from "./MobileMenu";
import Search from "../Search";
import overflowHidden from "../../hooks/overflowHidden";

export default function MobileView({isLoggedIn,locale, setIsLoggedIn, setMenuOpen, menuOpen, isDesktop, user, csrf, translations}) {

	return (
		<>
			<div
				className="header__ham-icon"
				tabIndex="0"
				aria-label="open mobile menu"
				onClick={() => {
					overflowHidden(menuOpen)
					setMenuOpen(true)
				}}
			>
				<span className="header__ham-icon--line"></span>
				<span className="header__ham-icon--line"></span>
				<span className="header__ham-icon--line"></span>
			</div>
			{menuOpen && (
				<MobileMenu
					locale={locale}
					menuOpen={menuOpen}
					isLoggedIn={isLoggedIn}
					setIsLoggedIn={setIsLoggedIn}
					setMenuOpen={setMenuOpen}
					user={user}
					csrf={csrf}
					translations={translations}
				/>
			)}
			<div className="header__mobile-icon">
				<a href="/">
					<img
						src="../../../images/png/McGill-LLDRD-icon.png"
						alt="McGill LLDRD logo"
					/>
				</a>
			</div>
			<Search
				isDesktop={isDesktop}
				locale={locale}
				translations={translations}
			/>
		</>
	);
}

MobileView.propTypes = {
	isLoggedIn: PropTypes.bool.isRequired,
	currentLocale: PropTypes.string,
	setIsLoggedIn: PropTypes.func.isRequired,
	setMenuOpen: PropTypes.func.isRequired,
	menuOpen: PropTypes.bool.isRequired,
	isDesktop: PropTypes.bool.isRequired,
	locale: PropTypes.string.isRequired,
	user: PropTypes.instanceOf(Object),
	csrf: PropTypes.string.isRequired,
	translations: PropTypes.instanceOf(Object).isRequired,
};
