import { useState } from "react";


// store the current value of an input, need to pass it an initial value of an empty string
// i.e. const email = useInput("")
export default function useInput(initialValue) {
	const [value, setValue] = useState(initialValue);


	const handleChange = (event) => {
		setValue(event.target.value);
	};

	return {
		value,
		onChange: handleChange,
	};
}
